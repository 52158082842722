import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Card, CardBody, CardTitle, Col, Row, Input, Button } from "reactstrap";

const CreateServer = () => {
  const [app_name, setAppName] = useState("");
  const [client_id, setClientId] = useState("");
  const [api_key, setApiKey] = useState("");
  const [server_id, setServerId] = useState("");
  const [salt, setSalt] = useState("");
  const [hash, setHash] = useState("");
  const [userId, setUserId] = useState("");
  const [authState, setAuthState] = useState({
    id: "",
    user_id: "",
  });

  const { id } = useParams();

  useEffect(() => {
    axios
      .get("http://195.87.127.148:3001/auth/auth", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.error) {
          setAuthState({ ...authState });
        } else {
          setAuthState({
            id: response.id,
            userId: response.id,
          });
          setUserId(response.id); // Set userId automatically
        }
      });
  }, []);

  const handlePostRequest = async () => {
    try {
      await axios.post("http://195.87.127.148:3001/server/", {
        app_name: app_name,
        client_id: client_id,
        api_key: api_key,
        server_id: server_id,
        salt: salt,
        hash: hash,
        userId: userId, // Use the automatically set userId
      });
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Row>
        <Col sm={12}>
          <Card>
            <CardBody>
              <CardTitle className="h4">Add Server</CardTitle>
              <div className="mb-3">
                <label htmlFor="example-text-input" className="form-label">
                  App Name
                </label>
                <Input
                  type="text"
                  value={app_name}
                  onChange={(e) => setAppName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="example-text-input" className="form-label">
                  Server ID
                </label>
                <Input
                  type="text"
                  value={server_id}
                  onChange={(e) => setServerId(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="example-text-input" className="form-label">
                  Client ID
                </label>
                <Input
                  type="text"
                  value={client_id}
                  onChange={(e) => setClientId(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="example-text-input" className="form-label">
                  API Key
                </label>
                <Input
                  type="text"
                  value={api_key}
                  onChange={(e) => setApiKey(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="example-text-input" className="form-label">
                  Salt
                </label>
                <Input
                  type="text"
                  value={salt}
                  onChange={(e) => setSalt(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="example-text-input" className="form-label">
                  Hash
                </label>
                <Input
                  type="text"
                  value={hash}
                  onChange={(e) => setHash(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="example-text-input" className="form-label">
                  User Id
                </label>
                <Input type="text" value={userId} disabled />
              </div>
              <div className="mt-4">
                <Button onClick={handlePostRequest} color="primary">
                  Add Server
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CreateServer;
