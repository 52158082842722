const initialState = {
  servers: [],
  currentServer: {},
};

const serverReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SERVERS":
      return {
        ...state,
        servers: action.payload,
      };
    case "SET_CURRENT_SERVER":
      return {
        ...state,
        currentServer: action.payload,
      };
    default:
      return state;
  }
};

export const setServers = (servers) => ({
  type: "SET_SERVERS",
  payload: servers,
});

export const setCurrentServer = (server) => ({
  type: "SET_CURRENT_SERVER",
  payload: server,
});

export default serverReducer;
