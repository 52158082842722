import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import Facebook from "./Republish/facebook";

const FacebookDetails = () => {
  const { id } = useParams();
  const [listOfRepublish, setListOfRepublish] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState("");
  const [authState, setAuthState] = useState({
    id: "",
    userId: "",
  });
  const [application, setApplication] = useState("");
  const [initialized, setInitialized] = useState(false);
  const [server_id, setServer_id] = useState("");
  const [client_id, setClient_id] = useState("");
  const [api_key, setApi_key] = useState("");

  const fetchServerInfo = () => {
    const currentServer = localStorage.getItem("currentServer");
    if (currentServer) {
      const serverInfo = JSON.parse(currentServer);
      setServer_id(serverInfo.server_id);
      setClient_id(serverInfo.client_id);
      setApi_key(serverInfo.api_key);
    }
  };

  useEffect(() => {
    fetchServerInfo();
  }, []);

  useEffect(() => {
    // Auth state fetch
    axios
      .get("http://195.87.127.148:3001/auth/auth", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.error) {
          setAuthState({ ...authState, status: false });
        } else {
          setAuthState({
            id: response.id,
            userId: response.id,
          });
          setUserId(response.id);
        }
      });
  }, []);

  const fetchStreamsData = async () => {
    try {
      const response = await axios.get(
        `http://195.87.127.148:3001/streams/${id}`
      );
      const stream = response;

      if (stream) {
        setApplication(stream.application);
        fetchData(); // Stream verisi varsa republish verilerini çek
      } else {
        console.warn("Not found any stream data.");
      }
    } catch (error) {
      console.error("Error fetching streams data: ", error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get("http://195.87.127.148:3001/republish/");
      if (response.length > 0) {
        const filteredRepublish = response.filter(
          (republish) => republish.src_app === application
        );
        const matchedRules = filteredRepublish.filter(
          (republish) => republish.dest_addr === "live-api-s.facebook.com"
        );
        //user id kontrolü yapılacak
        const matchedUser = matchedRules.filter(
          (republish) => republish.userId === authState.userId
        );
        setListOfRepublish(matchedUser);
      } else {
        console.warn("Republish verisi bulunamadı.");
      }
    } catch (err) {
      console.error("Error fetching data: ", err);
      setError("Verileri çekerken bir hata oluştu.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialized) {
      fetchData();
    }
  }, [initialized]); // initialized değiştiğinde fetchData'yı çağır

  useEffect(() => {
    if (id && !initialized) {
      fetchStreamsData();
      setInitialized(true);
    }
  }, [id, initialized]); // id değiştiğinde ve initialized false ise fetchStreamsData'yı çağır

  const handleButtonClick = () => {
    fetchData();
  };

  const fetchAndSendData = async () => {
    try {
      const response = await axios.get(
        `https://api.wmspanel.com/v1/server/${server_id}/rtmp/republish?client_id=${client_id}&api_key=${api_key}`
      );
      const rules = response.rules;
      if (Array.isArray(rules)) {
        for (const rule of rules) {
          try {
            await axios.post("http://195.87.127.148:3001/republish/", {
              rule_id: rule.id,
              src_app: rule.src_app,
              src_strm: rule.src_strm,
              dest_addr: rule.dest_addr,
              dest_port: rule.dest_port,
              dest_app: rule.dest_app,
              dest_strm: rule.dest_strm,
              description: "Some description",
              tags: ["tag1", "tag2"],
              custom_name: "",
              userId: userId,
            });
          } catch (error) {
            console.error("Error posting data: ", error);
          }
        }
        fetchData();
      } else {
        console.warn("Unexpected response: ", response);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetchFromLocal = async () => {
    try {
      const response = await axios.get("http://195.87.127.148:3001/republish/");
      if (response.length > 0) {
        const filteredRepublish = response.filter(
          (republish) => republish.src_app === application
        );
        const matchedRules = filteredRepublish.filter(
          (republish) => republish.dest_addr === "live-api-s.facebook.com"
        );
        // user id kontrolü yapılacak
        const matchedUser = matchedRules.filter(
          (republish) => republish.userId === authState.userId
        );
        setListOfRepublish(matchedUser);
      } else {
        console.warn("Republish verisi bulunamadı.");
      }
    } catch (err) {
      console.error("Error fetching data: ", err);
      setError("Verileri çekerken bir hata oluştu.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title text-center">Facebook Republish</h4>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg={12}>
                      <div className="text-end">
                        <Facebook />
                        <Button
                          color="primary"
                          className="font-16"
                          onClick={() => {
                            handleButtonClick();
                            fetchAndSendData();
                          }}
                        >
                          <i className="fas fa-recycle me-1"></i> Refresh
                        </Button>
                        <Button
                          color="info"
                          className="font-16 m-1"
                          onClick={() => {
                            fetchFromLocal();
                          }}
                        >
                          <i className="fas fa-download me-1"></i> Fetch
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className="table-responsive">
                        <table className="table table-centered table-nowrap table-striped">
                          <thead>
                            <tr>
                              <th scope="col" style={{ width: "70px" }}>
                                #
                              </th>
                              <th scope="col">Rule ID</th>
                              <th scope="col">Source Application</th>
                              <th scope="col">Source Stream</th>
                              <th scope="col">Destination Address</th>
                              <th scope="col">Destination Port</th>
                              <th scope="col">Destination Application</th>
                              <th scope="col">Destination Stream</th>
                              {/* <th scope="col">Active</th>
                              <th scope="col">Deactive</th> */}
                              <th scope="col">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listOfRepublish.map((republish, key) => (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{republish.rule_id}</td>
                                <td>{republish.src_app}</td>
                                <td>{republish.src_strm}</td>
                                <td>{republish.dest_addr}</td>
                                <td>{republish.dest_port}</td>
                                <td>{republish.dest_app}</td>
                                <td>{republish.dest_strm}</td>
                                {/* <td>
                                  <ButtonToggle
                                    color="primary"
                                    className="btn-sm"
                                    onClick={() => {
                                      axios
                                        .post(
                                          `https://api.wmspanel.com/v1/server/${server_id}/rtmp/republish/${republish.rule_id}/?client_id=${client_id}&api_key=${api_key}`,
                                          {
                                            enabled: !republish.enabled,
                                          }
                                        )
                                        .then(() => {
                                          fetchFromLocal();
                                          axios
                                            .put(
                                              `http://195.87.127.148:3001/republish/${republish.id}`,
                                              {
                                                enabled: !republish.enabled,
                                              }
                                            )
                                            .catch((error) => {
                                              console.error(
                                                "Error updating local data: ",
                                                error
                                              );
                                            });
                                          window.location.reload();
                                        })
                                        .catch((error) => {
                                          console.error(
                                            "Error updating data: ",
                                            error
                                          );
                                        });
                                    }}
                                  >
                                    {republish.enabled ? (
                                      <i className="fas fa-toggle-on"></i>
                                    ) : (
                                      <i className="fas fa-toggle-off"></i>
                                    )}
                                  </ButtonToggle>
                                </td> */}
                                <td>
                                  <Button
                                    color="danger"
                                    className="btn-sm"
                                    onClick={() => {
                                      axios
                                        .delete(
                                          `https://api.wmspanel.com/v1/server/${server_id}/rtmp/republish/${republish.rule_id}/?client_id=${client_id}&api_key=${api_key}`
                                        )
                                        .then(() => {
                                          fetchFromLocal();
                                          axios
                                            .delete(
                                              `http://195.87.127.148:3001/republish/${republish.id}`
                                            )
                                            .catch((error) => {
                                              console.error(
                                                "Error deleting local data: ",
                                                error
                                              );
                                            });
                                          window.location.reload();
                                        })
                                        .catch((error) => {
                                          console.error(
                                            "Error deleting data: ",
                                            error
                                          );
                                        });
                                    }}
                                  >
                                    <i className="fas fa-trash-alt"></i>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FacebookDetails;
