import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import axios from "axios"; // axios import edildi

// Login Redux States
import { EDIT_PROFILE } from "./actionTypes";
import { profileSuccess, profileError } from "./actions";

const API_URL = "http://195.87.127.148:3001"; // Kendi API'nizin URL'sini buraya girin

// Kendi API'nizden veri almak için bir fonksiyon oluşturalım
const editProfileAPI = async (username, idx) => {
  try {
    const response = await axios.put(`${API_URL}/edit-profile`, {
      username,
      idx,
    }); // Kendi endpoint'inizi buraya girin
    return response;
  } catch (error) {
    console.error("API request failed", error);
    throw error;
  }
};

function* editProfile({ payload: { user } }) {
  try {
    const response = yield call(editProfileAPI, user.username, user.idx);
    yield put(profileSuccess(response));
  } catch (error) {
    yield put(profileError(error));
  }
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile);
}

function* ProfileSaga() {
  yield all([fork(watchProfile)]);
}

export default ProfileSaga;
