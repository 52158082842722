import React from "react";
import { Link } from "react-router-dom";

import { Row, Col, CardBody, Card, Container, Button } from "reactstrap";

import logodark from "../../assets/images/logo-dark.png";
import darkbg from "../../assets/images/bg.jpg";

function Register() {
  return (
    <React.Fragment>
      <div
        style={{
          backgroundImage: `url(${darkbg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="fas fa-home h2"></i>
          </Link>
        </div>
        <div className="bg-overlay"></div>
        <div className="account-pages pt-4 ">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6} md={8} xl={4}>
                <Card className="mt-5">
                  <CardBody className="p-4">
                    <div className="text-center">
                      <Link to="/" className="">
                        <img
                          src={logodark}
                          alt=""
                          height="24"
                          className="auth-logo logo-dark mx-auto"
                        />
                      </Link>
                    </div>

                    <h4 className="font-size-18 text-muted text-center mt-2">
                      Register
                    </h4>
                    <p className="text-muted text-center mb-4">
                      Don't have an account? Contact us!
                    </p>
                    <div className="p-4 text-center">
                      <a href="tel:0312 472 22 33">
                        <Button
                          color="warning"
                          className="btn btn-primary waves-effect waves-light mt-3"
                        >
                          <i className="fas fa-phone"></i> Contact Us
                        </Button>
                      </a>
                      <a href="mailto: veriyum@veriyum.com">
                        <Button
                          color="info"
                          className="btn btn-primary waves-effect waves-light mt-3"
                        >
                          <i className="fas fa-envelope"></i> Email Us
                        </Button>
                      </a>
                    </div>
                    <div className="mt-3 text-center">
                      <Link to="/login">
                        <div className="mt-3 d-grid">
                          <Button
                            className="btn btn-primary waves-effect waves-light"
                            type="button"
                            color="primary"
                          >
                            Log In
                          </Button>
                        </div>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Register;
