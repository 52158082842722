import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// layouts
import NonAuthLayout from "../Layout/NonAuthLayout";
import UserLayout from "../Layout/UserLayout/index";
import AdminLayout from "../Layout/AdminLayout/index";
import ResellerLayout from "../Layout/ResellerLayout";
import { AuthProtected } from "./AuthProtected";
import { authProtectedRoutes, publicRoutes } from "./routes";

import axios from "axios";

const Index = () => {
  const [authState, setAuthState] = useState({
    email: "",
    id: 0,
    status: false,
    username: "",
    role: "",
    image: "",
  });
  useEffect(() => {
    axios
      .get("http://195.87.127.148:3001/auth/auth", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.error) {
          setAuthState({ ...authState, status: false });
        } else {
          setAuthState({
            email: response.email,
            id: response.id,
            status: true,
            username: response.username,
            role: response.role, // Kullanıcının rol bilgisini al
            image: response.image,
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      {!authState.status ? (
        <Route>
          {publicRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={<NonAuthLayout>{route.element}</NonAuthLayout>}
            />
          ))}
        </Route>
      ) : (
        <Route>
          {authProtectedRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={
                <AuthProtected>
                  {authState.role === "admin" && route.role === "admin" ? (
                    <AdminLayout>{route.element}</AdminLayout>
                  ) : authState.role === "user" && route.role === "user" ? (
                    <UserLayout>{route.element}</UserLayout>
                  ) : authState.role === "reseller" &&
                    route.role === "reseller" ? (
                    <ResellerLayout>{route.element}</ResellerLayout>
                  ) : authState.role === "user" ? (
                    <Navigate to="/dashboard" />
                  ) : authState.role === "reseller" ? (
                    <Navigate to="/reseller-dashboard" />
                  ) : (
                    <Navigate to="/admin-dashboard" />
                  )}
                </AuthProtected>
              }
            />
          ))}
        </Route>
      )}
    </Routes>
  );
};
export default Index;
