import React from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useState } from "react";
import { Overview, Webrtc, HLSPlayer, Transfer } from "./Overview/index";

const View = () => {
  const [activeTab, setactiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  return (
    <Row>
      <Col xl={12}>
        <Card>
          <CardBody>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{
                    cursor: "pointer",
                  }}
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  <i className="fa fa-server me-1 align-middle"> </i> SLDP
                  PLAYER
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  <i className="fas fa-code me-1 align-middle"></i> WEBRTC
                  PLAYER
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "3",
                  })}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  <i className="fa fa-chart-line me-1 align-middle"></i>
                  HLS PLAYER
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "4",
                  })}
                  onClick={() => {
                    toggle("4");
                  }}
                >
                  <i className="fas fa-database me-1 align-middle"></i> DATA
                  TRANSFER
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab} className="p-3">
              <TabPane tabId="1" id="overview">
                <Overview />
              </TabPane>
              <TabPane tabId="2" id="webrtc">
                <Webrtc />
              </TabPane>
              <TabPane tabId="3" id="hls">
                <HLSPlayer />
              </TabPane>
              <TabPane tabId="4" id="test">
                <Transfer />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default View;
