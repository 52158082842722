import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  CardImg,
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";

const UserProfile = () => {
  const [showModal, setShowModal] = useState(false);
  const [username, setUsername] = useState("");
  document.title = `${username} Profile | Veriyum - Admin & Dashboard`;
  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [number, setNumber] = useState("");
  const [adress, setAdress] = useState("");
  const [cName, setCName] = useState("");
  const [cAdress, setCAdress] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const [id, setId] = useState("");
  const [service, setService] = useState([]);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      axios
        .get("http://195.87.127.148:3001/auth/auth", {
          headers: {
            accessToken: accessToken,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setUsername(response.username);
          setId(response.id);
          setImage(response.image);
          setEmail(response.email);
          setRole(response.role.toUpperCase());
          setNumber(response.number);
          setAdress(response.adress);
          setCName(response.cName);
          setCAdress(response.cAdress);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  useEffect(() => {
    axios.get(`http://195.87.127.148:3001/customer/${id}`).then((response) => {
      setService(response);
    });
  }, []);

  const handleChangePassword = async () => {
    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }

    try {
      const response = await axios.put(
        `http://195.87.127.148:3001/auth/${id}/changepassword`,
        {
          password: password,
          confirmPassword: confirmPassword,
        },
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      );

      console.log(response);
      setShowModal(false);
      setMessage("Password changed successfully.");
    } catch (error) {
      setMessage("An error occurred while changing the password.");
    }
  };

  return (
    <div className="gradient-custom-2">
      <div className="py-5 h-100">
        <Row className="justify-content-center align-items-center h-100">
          <Col lg="9" xl="7">
            <Card>
              <div
                className="rounded-top text-white d-flex flex-row"
                style={{ backgroundColor: "#04321", height: "200px" }}
              >
                <div
                  className="ms-4 mt-5 d-flex flex-column"
                  style={{ width: "150px" }}
                >
                  <CardImg
                    src={image}
                    alt="Avatar"
                    className="mt-4 mb-2 rounded-circle"
                    style={{ width: "150px", zIndex: "1" }}
                  />
                </div>
                <div className="ms-2" style={{ marginTop: "120px" }}>
                  <CardTitle tag="h5">
                    {username} / {role} / ID: {id}
                  </CardTitle>
                  <CardText className="text-white mb-1">{number}</CardText>
                  <CardText style={{ color: "white" }}>{adress}</CardText>
                </div>
              </div>

              <CardBody className="text-white p-4">
                <div className="mb-5">
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <CardText className="lead fw-normal mb-0">
                      <Button
                        color="primary"
                        onClick={() => setShowModal(true)}
                        className="mt-3"
                      >
                        Change Password
                      </Button>
                    </CardText>
                  </div>
                  <p className="lead fw-normal mb-1">About</p>
                  <div className="p-4">
                    <Row>
                      <Col sm="3">
                        <CardText>Email</CardText>
                      </Col>
                      <Col sm="9">
                        <CardText className="text-muted">{email}</CardText>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="3">
                        <CardText>Company Name</CardText>
                      </Col>
                      <Col sm="9">
                        <CardText className="text-muted">{cName}</CardText>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="3">
                        <CardText>Company Adress</CardText>
                      </Col>
                      <Col sm="9">
                        <CardText className="text-muted">{cAdress}</CardText>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <CardText className="lead fw-normal mb-0">Services</CardText>
                  <CardText className="mb-0">
                    <Link to={"/services"} className="text-muted">
                      Show all
                    </Link>
                  </CardText>
                </div>
                <Row>
                  <Col className="mb-2">
                    <CardImg
                      src="https://mdbcdn.b-cdn.net/img/Photos/Lightbox/Original/img%20(112).webp"
                      alt="image 1"
                      className="w-100 rounded-3"
                    />
                  </Col>
                  <Col className="mb-2">
                    <CardImg
                      src="https://mdbcdn.b-cdn.net/img/Photos/Lightbox/Original/img%20(107).webp"
                      alt="image 1"
                      className="w-100 rounded-3"
                    />
                  </Col>
                </Row>
                <Row className="g-2">
                  <Col className="mb-2">
                    <CardImg
                      src="https://mdbcdn.b-cdn.net/img/Photos/Lightbox/Original/img%20(108).webp"
                      alt="image 1"
                      className="w-100 rounded-3"
                    />
                  </Col>
                  <Col className="mb-2">
                    <CardImg
                      src="https://mdbcdn.b-cdn.net/img/Photos/Lightbox/Original/img%20(114).webp"
                      alt="image 1"
                      className="w-100 rounded-3"
                    />
                  </Col>
                </Row>
                <div className="d-flex justify-content-between align-items-center mb-4"></div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <CardText className="lead fw-normal mb-0">{message}</CardText>
                </div>

                <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
                  <ModalHeader toggle={() => setShowModal(false)}>
                    Change Password
                  </ModalHeader>
                  <ModalBody>
                    <Form>
                      <FormGroup>
                        <Label for="password">Password</Label>
                        <Input
                          type="password"
                          name="password"
                          id="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="confirmPassword">Confirm Password</Label>
                        <Input
                          type="password"
                          name="confirmPassword"
                          id="confirmPassword"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </FormGroup>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="secondary"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </Button>
                    <Button color="primary" onClick={handleChangePassword}>
                      Save changes
                    </Button>
                  </ModalFooter>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default UserProfile;
