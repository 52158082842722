import React, { useEffect, useState } from "react";
import { Row, Table } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import SLDPPlayer from "../sldpplayer";
import Facebook from "./Republish/facebook";
import Twitch from "./Republish/twitch";
import Twitter from "./Republish/twitter";
import Youtube from "./Republish/youtube";
import Custom from "./Republish/custom";

function Overview() {
  const { id } = useParams();
  const [service, setService] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [streamId, setStreamId] = useState("");
  const [server_id, setServer_id] = useState("");
  const [client_id, setClient_id] = useState("");
  const [api_key, setApi_key] = useState("");

  const fetchServerInfo = () => {
    const currentServer = localStorage.getItem("currentServer");
    if (currentServer) {
      const serverInfo = JSON.parse(currentServer);
      setServer_id(serverInfo.server_id);
      setClient_id(serverInfo.client_id);
      setApi_key(serverInfo.api_key);
    }
  };

  useEffect(() => {
    fetchServerInfo();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (server_id && client_id && api_key) {
      axios
        .get(
          `https://api.wmspanel.com/v1/server/${server_id}/live/streams?client_id=${client_id}&api_key=${api_key}`
        )
        .then((res) => {
          const foundStream = res.streams.find((stream) => stream.id === id);
          if (foundStream) {
            setService(foundStream);
          } else {
            navigate("/404");
          }
        })
        .catch((error) => {
          console.error(error);
          setError(error.message);
        })
        .finally(() => {
          setIsLoaded(true);
        });
    }
  }, [id, navigate, server_id, client_id, api_key]);

  useEffect(() => {
    if (streamId) {
      axios
        .get(`http://195.87.127.148:3001/server/find/${id}`)
        .then((res) => {
          const sortedStreams = res.streams.sort((a, b) =>
            a.stream.localeCompare(b.stream)
          );
          setService(sortedStreams[streamId]);
        })
        .catch((err) => {
          navigate("/404");
        });
    }
  }, [id, streamId, navigate]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 500);
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Row>
          <div className="col-xs-12 text-end m-auto">
            <span className="ml-2 p-2">
              {service.status === "online" ? (
                <i className="fa fa-circle text-success"></i>
              ) : (
                <i className="fa fa-circle text-danger"></i>
              )}
            </span>

            <span
              className={`ServiceStatus text-large text-bold ${
                service.status === "online" ? "text-success" : "text-danger"
              }`}
            >
              {service.status ? service.status.toUpperCase() : ""}
            </span>
          </div>
        </Row>
        <div className="col-sm-6 col-xl-4 mb-3">
          <div className="table-responsive">
            <Table responsive className="table-nowrap mb-0">
              <tbody>
                <tr>
                  <td>Stream ID</td>
                  <td>{service.id}</td>
                </tr>
                <tr>
                  <td>Application</td>
                  <td>{service.application}</td>
                </tr>
                <tr>
                  <td>Audio Codec</td>
                  <td>{service.audio_codec}</td>
                </tr>
                <tr>
                  <td>Stream Description</td>
                  <td>{service.description}</td>
                </tr>
                <tr>
                  <td>bandwidth</td>
                  <td>{service.bandwidth}</td>
                </tr>
                <tr>
                  <td>Stream Protocol</td>
                  <td>{service.protocol}</td>
                </tr>
                <tr>
                  <td>Stream Publish Time</td>
                  <td>
                    {service.publish_time && !isNaN(service.publish_time)
                      ? new Date(service.publish_time * 1000)
                          .toISOString()
                          .substr(11, 8)
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <td>Stream Status</td>
                  <td>{service.status}</td>
                </tr>
                <tr>
                  <td>Stream</td>
                  <td>{service.stream}</td>
                </tr>
                <tr>
                  <td>Video Codec</td>
                  <td>{service.video_codec}</td>
                </tr>
              </tbody>
            </Table>
            <Facebook />
            <Twitch />
            <Twitter />
            <Youtube />
            <Custom />
          </div>
        </div>
        <div className="col-sm-12 col-xl-8 mb-3">
          <div className="embed-responsive embed-responsive-16by9">
            {isLoaded && (
              <SLDPPlayer
                className="embed-responsive-item"
                streamUrl={`195.87.127.140:8081/${service.application}/${service.stream}`}
                style={{
                  position: "relative",
                  width: "auto",
                  height: "auto",
                }}
                muted
                autoPlay
              />
            )}
          </div>
        </div>
      </Row>
    </React.Fragment>
  );
}

export default Overview;
