import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
  Progress,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import axios from "axios";

// Import Flatepicker

// Import Images

const Services = () => {
  const [listOfCustomers, setListOfCustomers] = useState([]);
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const handleDelete = (id) => {
    const data = listOfCustomers.filter((service) => service.id !== id);
    setListOfCustomers(data);
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      axios
        .get("http://195.87.127.148:3001/auth/auth", {
          headers: { accessToken, "Content-Type": "application/json" },
        })
        .then((response) => {
          if (response && response.username) {
            const username = response.username;
            axios
              .get(`http://195.87.127.148:3001/customer`)
              .then((response) => {
                const filteredCustomers = Array.isArray(response)
                  ? response.filter(
                      (customer) => customer.createdBy === username
                    )
                  : [];

                setListOfCustomers(filteredCustomers);
              })
              .catch((error) => {
                console.error(
                  "Error fetching customer list for user",
                  username,
                  ":",
                  error
                );
              });
          } else {
            console.error("Geçersiz kullanıcı bilgileri.");
          }
        })
        .catch((error) => {
          console.error("Kimlik doğrulama hatası:", error);
        });
    }
  }, []); // Sadece bir kez çalışması için boş bağımlılık dizisi

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Services" breadcrumbItem="Services" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div id="customerList">
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="customerTable"
                      >
                        <thead className="table-light">
                          <tr>
                            <th className="sort" data-sort="customer_name">
                              Customer
                            </th>
                            <th className="sort" data-sort="type">
                              Service Type
                            </th>
                            <th className="sort" data-sort="publish">
                              Publish Name
                            </th>
                            <th className="sort" data-sort="connections">
                              Connections
                            </th>
                            <th className="sort" data-sort="band">
                              Bandwidth
                            </th>
                            <th className="sort" data-sort="bandstorage">
                              Storage
                            </th>
                            <th className="sort" data-sort="status">
                              Status
                            </th>
                            <th className="sort" data-sort="action">
                              Edit
                            </th>
                            <th className="sort" data-sort="action">
                              Remove
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list">
                          {Array.isArray(listOfCustomers) &&
                            listOfCustomers.map((service, key) => (
                              <tr key={"_service_" + key}>
                                <td className="customer_name">
                                  <h5 className="text-truncate font-size-14 mb-1">
                                    <img
                                      src={service.image}
                                      alt="avatar"
                                      className="avatar-md rounded-circle me-2"
                                    />
                                    {service.firstName} {service.lastName}
                                  </h5>
                                </td>
                                <td className="type">
                                  <h5 className="text-truncate font-size-14 mb-1">
                                    <Link
                                      to={`/reseller-services/${service.id}`}
                                      className="text-success"
                                    >
                                      {service.type}
                                    </Link>
                                  </h5>
                                </td>
                                <td className="publish">
                                  <h5 className="text-truncate font-size-14 mb-1">
                                    {service.publish}
                                  </h5>
                                </td>
                                <td className="connections">
                                  <h5 className="text-truncate font-size-14 mb-1">
                                    {service.connections} / 10 @ 2048K
                                  </h5>
                                  <Progress
                                    value={service.connections * 10}
                                    color={
                                      service.connections >= 1 &&
                                      service.connections <= 3
                                        ? "success"
                                        : service.connections >= 4 &&
                                          service.connections <= 6
                                        ? "warning"
                                        : "danger"
                                    }
                                    style={{ width: "60%" }}
                                    animated
                                  ></Progress>
                                </td>
                                <td className="band">
                                  <h5 className="text-truncate  font-size-14 mb-1">
                                    {service.band} / 10 GB
                                  </h5>
                                  <Progress
                                    value={service.band * 10}
                                    color={
                                      service.band >= 0 && service.band <= 3.9
                                        ? "success"
                                        : service.band >= 4 &&
                                          service.band <= 6.9
                                        ? "warning"
                                        : "danger"
                                    }
                                    style={{ width: "60%" }}
                                    animated
                                  ></Progress>
                                </td>
                                <td className="bandstorage">
                                  <h5 className="text-truncate font-size-14 mb-1">
                                    {service.bandstorage} GB / 60 GB
                                  </h5>
                                  <Progress
                                    value={service.bandstorage * 2}
                                    color={
                                      service.bandstorage >= 0 &&
                                      service.bandstorage <= 19.9
                                        ? "success"
                                        : service.bandstorage >= 20 &&
                                          service.bandstorage <= 39.9
                                        ? "warning"
                                        : "danger"
                                    }
                                    style={{ width: "60%" }}
                                    animated
                                  ></Progress>
                                </td>
                                <td className="status">
                                  <h5 className="text-truncate font-size-14 mb-1">
                                    <span
                                      className={
                                        service.status === "Active"
                                          ? "badge bg-success"
                                          : "badge bg-danger"
                                      }
                                      style={{ color: "black" }}
                                    >
                                      {service.status}
                                    </span>
                                  </h5>
                                </td>
                                <td>
                                  <div className="edit">
                                    <Link
                                      to="#"
                                      className="text-success"
                                      id="edit-btn"
                                    >
                                      <i className="ri-pencil-line"></i>
                                    </Link>
                                  </div>
                                </td>
                                <td>
                                  <div className="delete">
                                    <Link
                                      to="#"
                                      className="text-danger"
                                      onClick={() => handleDelete(service.id)}
                                    >
                                      <i className="ri-delete-bin-2-line"></i>
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      <div className="noresult" style={{ display: "none" }}>
                        <div className="text-center">
                          <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#121331,secondary:#08a88a"
                            style={{ width: "75px", height: "75px" }}
                          ></lord-icon>
                          <h5 className="mt-2">Sorry! No Result Found</h5>
                          <p className="text-muted mb-0">
                            We've searched more than 150+ Orders We did not find
                            any orders for you search.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Service</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <div>
                <div className="mb-3">
                  <label className="form-label">Customer Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Service Type </label>
                  <input type="text" className="form-control" />
                </div>
                <div className="mb-3">
                  <label className="form-label">Publish Name </label>
                  <input type="text" className="form-control" />
                </div>
                <div className="mb-3">
                  <label className="form-label">Storage</label>
                  <input type="text" className="form-control" />
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
export default Services;
