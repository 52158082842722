import React from "react";
import {
  Col,
  Card,
  CardBody,
  CardTitle,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useState } from "react";
import {
  View,
  Republish,
  PublicPage,
  FileManager,
  Schedule,
  WaterMark,
  Targets,
  CountryLock,
  Reporting,
} from "./SidebarData/index";

const ServiceSidebar = () => {
  const [verticalActiveTabWithIcon, setverticalActiveTabWithIcon] =
    useState("1");

  const toggleVerticalIcon = (tab) => {
    if (verticalActiveTabWithIcon !== tab) {
      setverticalActiveTabWithIcon(tab);
    }
  };

  return (
    <Row>
      <Col xl={12}>
        <Card>
          <CardBody>
            <CardTitle className="h3 p-3 text-center">| Veriyum | </CardTitle>
            <Row>
              <Col md="2">
                <Nav pills className="flex-column">
                  <NavItem>
                    <NavLink
                      id="v-pills-left-home-tab"
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTabWithIcon === "1",
                      })}
                      onClick={() => {
                        toggleVerticalIcon("1");
                      }}
                    >
                      <i className=" dripicons-view-list me-2 align-middle">
                        {" "}
                      </i>{" "}
                      Overview
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      id="v-pills-left-messages-tab"
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTabWithIcon === "3",
                      })}
                      onClick={() => {
                        toggleVerticalIcon("3");
                      }}
                    >
                      <i className="dripicons-browser me-2 align-middle"></i>{" "}
                      Republish
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      id="v-pills-left-setting-tab"
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: verticalActiveTabWithIcon === "4",
                      })}
                      onClick={() => {
                        toggleVerticalIcon("4");
                      }}
                    >
                      <i className="dripicons-blog me-2 align-middle"></i>{" "}
                      Public Page
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      id="v-pills-left-setting-tab"
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: verticalActiveTabWithIcon === "5",
                      })}
                      onClick={() => {
                        toggleVerticalIcon("5");
                      }}
                    >
                      <i className="dripicons-folder me-2 align-middle"></i>{" "}
                      File Manager
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      id="v-pills-left-setting-tab"
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: verticalActiveTabWithIcon === "6",
                      })}
                      onClick={() => {
                        toggleVerticalIcon("6");
                      }}
                    >
                      <i className="dripicons-monitor me-2 align-middle"></i> TV
                      Schedule
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      id="v-pills-left-setting-tab"
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: verticalActiveTabWithIcon === "7",
                      })}
                      onClick={() => {
                        toggleVerticalIcon("7");
                      }}
                    >
                      <i className="dripicons-photo-group me-2 align-middle"></i>{" "}
                      Stream Watermark
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      id="v-pills-left-setting-tab"
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: verticalActiveTabWithIcon === "8",
                      })}
                      onClick={() => {
                        toggleVerticalIcon("8");
                      }}
                    >
                      <i className="dripicons-forward me-2 align-middle"></i>{" "}
                      Stream Targets
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      id="v-pills-left-setting-tab"
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: verticalActiveTabWithIcon === "9",
                      })}
                      onClick={() => {
                        toggleVerticalIcon("9");
                      }}
                    >
                      <i className="dripicons-lock me-2 align-middle"></i> GeoIP
                      Country Locking
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      id="v-pills-left-setting-tab"
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: verticalActiveTabWithIcon === "10",
                      })}
                      onClick={() => {
                        toggleVerticalIcon("10");
                      }}
                    >
                      <i className="dripicons-graph-pie me-2 align-middle"></i>{" "}
                      Reporting
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      id="v-pills-left-setting-tab"
                      style={{
                        cursor: "pointer",
                        color: "orange",
                      }}
                      className={classnames({
                        active: verticalActiveTabWithIcon === "11",
                      })}
                      onClick={() => {
                        toggleVerticalIcon("11");
                      }}
                    >
                      <i className="dripicons-lock me-2 align-middle"></i>{" "}
                      Suspend Service
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      id="v-pills-left-setting-tab"
                      style={{
                        cursor: "pointer",
                        color: "red",
                        borderColor: "red",
                      }}
                      className={classnames({
                        active: verticalActiveTabWithIcon === "12",
                      })}
                      onClick={() => {
                        toggleVerticalIcon("12");
                      }}
                    >
                      <i className="dripicons-trash me-2 align-middle"></i>{" "}
                      Delete Service
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col md="9">
                <TabContent
                  activeTab={verticalActiveTabWithIcon}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <View />
                  </TabPane>
                  <TabPane tabId="3">
                    <Republish />
                  </TabPane>
                  <TabPane tabId="4">
                    <PublicPage />
                  </TabPane>
                  <TabPane tabId="5">
                    <FileManager />
                  </TabPane>
                  <TabPane tabId="6">
                    <Schedule />
                  </TabPane>
                  <TabPane tabId="7">
                    <WaterMark />
                  </TabPane>
                  <TabPane tabId="8">
                    <Targets />
                  </TabPane>
                  <TabPane tabId="9">
                    <CountryLock />
                  </TabPane>
                  <TabPane tabId="10">
                    <Reporting />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ServiceSidebar;
