import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";

function ResetPassword() {
  const { id } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [customer, setCustomer] = useState({});

  useEffect(() => {
    axios
      .get(`http://195.87.127.148:3001/customer/${id}`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        setCustomer(res);
      })
      .catch((err) => {});
  }, []);

  const handleResetPassword = (e) => {
    e.preventDefault();
    axios
      .put(
        `http://195.87.127.148:3001/auth/${id}/changepassword`,
        {
          password: password,
          confirmPassword: confirmPassword,
        },
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      )
      .then((res) => {})
      .catch((err) => {});
  };

  return (
    <>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4 ">
                        <h4 className="text-primary">Welcome Back !</h4>
                        <p>
                          Reset your password for <br />
                          <span className="fw-bold">
                            {customer.firstName} {customer.lastName}{" "}
                          </span>
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={customer.image} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Form onSubmit={handleResetPassword}>
                      <FormGroup>
                        <Label htmlFor="password">Password</Label>
                        <Input
                          type="password"
                          name="password"
                          id="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Enter password"
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="confirmPassword">
                          Confirm Password
                        </Label>
                        <Input
                          type="password"
                          name="confirmPassword"
                          id="confirmPassword"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          placeholder="Enter confirm password"
                          required
                        />
                      </FormGroup>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link
                    to={`/admin-customer/${id}`}
                    className="font-weight-medium text-primary"
                  >
                    Customer Profile
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Veriyum. All rights reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ResetPassword;
