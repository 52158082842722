import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useIdleTimer } from "react-idle-timer";
import { Row, Col, CardBody, Card, Container, Button } from "reactstrap";

import logodark from "../../assets/images/logo-dark.png";
import darkbg from "../../assets/images/bg.jpg";

import { AuthContext } from "../../helpers/AuthContext";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setAuthState } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleOnIdle = () => {
    localStorage.clear();
    navigate("/login");
  };

  useIdleTimer({
    timeout: 3600 * 1000 * 12,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  useEffect(() => {
    const handleEnterPress = (event) => {
      if (event.key === "Enter") {
        login();
      }
    };

    window.addEventListener("keydown", handleEnterPress);

    return () => {
      window.removeEventListener("keydown", handleEnterPress);
    };
  }, [email, password]);

  const login = async () => {
    const data = { email: email, password: password };
    try {
      const response = await axios.post(
        "http://195.87.127.148:3001/auth/login",
        data
      );
      if (response.error) {
        alert(response.error);
      } else {
        // Set expiration time (e.g., 1 minute for testing)
        const expirationTime = new Date(Date.now() + 1 * 60 * 1000); // 1 minute in milliseconds

        // Store accessToken and expiration time in localStorage
        localStorage.setItem("accessToken", response.token);
        localStorage.setItem(
          "tokenExpiration",
          expirationTime.getTime().toString()
        );

        setAuthState({
          email: response.email,
          id: response.id,
          status: true,
          role: response.role,
        });

        if (response.role === "admin") {
          window.location.href = "/admin-dashboard";
        } else if (response.role === "user") {
          window.location.href = "/dashboard";
        } else if (response.role === "reseller") {
          window.location.href = "/reseller-dashboard";
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <style>
        {`
          body {
            background-image: url(${darkbg});
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            height: 100vh;
          }
        `}
      </style>
      <div className="home-btn d-none d-sm-block"></div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card
                className="overflow-hidden"
                style={{
                  borderRadius: "30px",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                  backdropFilter: "blur(10px)",
                  border: "1px solid rgba(0, 0, 0, 0.125)",
                }}
              >
                <div className="bg-soft-primary">
                  <div className="text-center">
                    <Link to="/" className="">
                      <img
                        src={logodark}
                        alt=""
                        height="48"
                        className="auth-logo logo-dark mx-auto"
                      />
                    </Link>
                  </div>
                  <Row>
                    <Col className="font-size-18 text-muted text-center mt-2">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Veriyum.</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <div className="p-2 mt-4">
                      <div className="mb-3">
                        <label htmlFor="emailaddress" className="form-label">
                          Email address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="emailaddress"
                          required=""
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter your email"
                        />
                      </div>
                      <div className="mb-3">
                        <Link
                          to="/pages-recoverpw-2"
                          className="text-muted float-end"
                        >
                          <small>Forgot your password?</small>
                        </Link>
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          required=""
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Enter your password"
                        />
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="checkbox-signin"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="checkbox-signin"
                        >
                          Remember me
                        </label>
                      </div>
                      <div className="mt-3 d-grid">
                        <Button
                          color="primary"
                          onClick={login}
                          type="submit"
                          className="waves-effect waves-light"
                        >
                          Log In
                        </Button>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/register" className="text-muted">
                          <i className="mdi mdi-account-circle me-1"></i>
                          Create an account
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
export default Login;
