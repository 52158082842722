import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import axios from "axios";
import classnames from "classnames";
import { Dash, GetServer, UserDash } from "./serverindex";

const Dashboard = () => {
  const [activeTab, setactiveTab] = useState("1");
  const [authState, setAuthState] = useState({
    role: "",
  });
  const [role, setRole] = useState("");
  useEffect(() => {
    axios
      .get("http://195.87.127.148:3001/auth/auth", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.error) {
          setAuthState((prevState) => ({ ...prevState, status: false }));
        } else {
          setAuthState({
            role: response.role,
          });
          setRole(response.role);
        }
      })
      .catch((error) => {
        console.error("Auth error:", error);
      });
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  return (
    <>
      <Row className="page-content">
        <Col xl={12}>
          <Card>
            <CardBody>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{
                      cursor: "pointer",
                    }}
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                    hidden
                  >
                    <i className="fa fa-server me-1 align-middle"> </i> Get
                    Server
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2");
                    }}
                    hidden
                  >
                    <i className="fas fa-wrench align-middle"></i> Create Server
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab} className="p-3">
                {role === "admin" ? (
                  <TabPane tabId="1" id="getServer">
                    <Dash />
                  </TabPane>
                ) : (
                  <TabPane tabId="1" id="getServer">
                    <UserDash />
                  </TabPane>
                )}
                <TabPane tabId="2" id="updateServer">
                  <GetServer />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
