import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardText,
  CardImg,
  Breadcrumb,
  BreadcrumbItem,
  ModalBody,
  ModalHeader,
  Modal,
  Button,
} from "reactstrap";
import axios from "axios";
import { useParams } from "react-router-dom";

const UserProfile = () => {
  document.title = "User Profile | Veriyum - Admin & Dashboard";

  const { id } = useParams();
  const [username, setUsername] = useState("");
  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [number, setNumber] = useState("");
  const [adress, setAdress] = useState("");
  const [cName, setCName] = useState("");
  const [cAdress, setCAdress] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [authState, setAuthState] = useState({
    id: "",
    userId: "",
  });

  const [formData, setFormData] = useState({
    name: "",
    url: "",
    dest_app: "",
    userId: userId,
  });

  useEffect(() => {
    // Auth state fetch
    axios
      .get("http://195.87.127.148:3001/auth/auth", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.error) {
          setAuthState({ ...authState, status: false });
        } else {
          setAuthState({
            id: response.id,
            userId: response.id,
          });
          setUserId(response.id);
        }
      });
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      axios
        .get("http://195.87.127.148:3001/auth/auth", {
          headers: {
            accessToken: accessToken,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setUsername(response.username);
          setImage(response.image);
          setEmail(response.email);
          setRole(response.role.toUpperCase());
          setNumber(response.number);
          setAdress(response.adress);
          setCName(response.cName);
          setCAdress(response.cAdress);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://195.87.127.148:3001/social",
        formData,
        {
          headers: {
            userId: localStorage.getItem("userId"),
          },
        }
      );
      toggleModal();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      userId: userId,
    }));
  }, [userId]);

  return (
    <React.Fragment>
      <section>
        <Container className="py-5 p-3">
          <Row className="">
            <Col className="py-5">
              <Breadcrumb className="bg-light rounded-3 p-3 mb-4">
                <BreadcrumbItem>
                  <a href="/admin-dashboard">Dashboard</a>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <a href="/admin-profile">Admin</a>
                </BreadcrumbItem>
                <BreadcrumbItem active>Admin Profile</BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>

          <Row>
            <Col lg="4">
              <Card className="mb-4">
                <CardBody className="text-center">
                  <CardImg
                    src={image}
                    alt="avatar"
                    className="rounded-circle"
                    style={{ width: "130px" }}
                  />
                  <p className="text-muted mb-1">{cName}</p>
                  <hr />
                  <p className="text-muted mb-4">{cAdress}</p>
                  <div className="d-flex justify-content-center mb-2"></div>
                </CardBody>
              </Card>
            </Col>

            <Col lg="8">
              <Card className="mb-4">
                <Button className="btn-primary" onClick={toggleModal}>
                  Add Social Media
                </Button>

                <Modal isOpen={isModalOpen} toggle={toggleModal}>
                  <ModalHeader toggle={toggleModal}>
                    Add Social Media
                  </ModalHeader>
                  <ModalBody>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="url">URL</label>
                        <input
                          type="text"
                          className="form-control"
                          id="url"
                          name="url"
                          value={formData.url}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="dest_app">Destination App</label>
                        <input
                          type="text"
                          className="form-control"
                          id="dest_app"
                          name="dest_app"
                          value={formData.dest_app}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="userId">User ID</label>
                        <input
                          type="text"
                          className="form-control"
                          id="userId"
                          name="userId"
                          value={formData.userId}
                          onChange={handleInputChange}
                          disabled
                        />
                      </div>

                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </form>
                  </ModalBody>
                </Modal>
                <CardBody>
                  <Row>
                    <Col sm="3">
                      <CardText>Full Name</CardText>
                    </Col>
                    <Col sm="9">
                      <CardText className="text-muted">{username}</CardText>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col sm="3">
                      <CardText>Email</CardText>
                    </Col>
                    <Col sm="9">
                      <CardText className="text-muted">{email}</CardText>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col sm="3">
                      <CardText>Phone</CardText>
                    </Col>
                    <Col sm="9">
                      <CardText className="text-muted">{number}</CardText>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col sm="3">
                      <CardText>Adress</CardText>
                    </Col>
                    <Col sm="9">
                      <CardText className="text-muted">{adress}</CardText>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col sm="3">
                      <CardText>Role</CardText>
                    </Col>
                    <Col sm="9">
                      <CardText className="text-muted">{role}</CardText>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default UserProfile;
