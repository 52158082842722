import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import {
  Facebook,
  Twitch,
  YouTube,
  Twitter,
  Custom,
} from "./RepublishData/index";

function Republish() {
  const [verticalActiveTabWithIcon, setverticalActiveTabWithIcon] =
    useState("1");

  const toggleVerticalIcon = (tab) => {
    if (verticalActiveTabWithIcon !== tab) {
      setverticalActiveTabWithIcon(tab);
    }
  };

  //Önemli Not:

  //* Bu sayfa özelinde, kullanıcı verilerini direkt olarak veritabanına post edip, ardından kontrollerini wmspanel üzerinden yapabiliriz.
  //* Veriler hala sabit olacak, sadece kullanıcıların veritabanına post edilmesi gerekiyor.
  //* Bu sayede, kullanıcılarımızın veritabanına post edilen verileri wmspanel üzerinden kontrol edebiliriz.

  return (
    <Container fluid>
      <Row>
        <Col lg="12">
          <Card>
            <CardHeader>
              <Nav pills className="navtab-bg nav-justified">
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({
                      active: verticalActiveTabWithIcon === "1",
                    })}
                    onClick={() => {
                      toggleVerticalIcon("1");
                    }}
                  >
                    <i className="bx bxl-facebook"></i>
                    <span className="d-none d-md-block">Facebook</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({
                      active: verticalActiveTabWithIcon === "2",
                    })}
                    onClick={() => {
                      toggleVerticalIcon("2");
                    }}
                  >
                    <i className="bx bxl-twitter"></i>
                    <span className="d-none d-md-block">Twitter</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({
                      active: verticalActiveTabWithIcon === "3",
                    })}
                    onClick={() => {
                      toggleVerticalIcon("3");
                    }}
                  >
                    <i className="bx bxl-twitch"></i>
                    <span className="d-none d-md-block">Twitch</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({
                      active: verticalActiveTabWithIcon === "4",
                    })}
                    onClick={() => {
                      toggleVerticalIcon("4");
                    }}
                  >
                    <i className="bx bxl-youtube"></i>
                    <span className="d-none d-md-block">YouTube</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({
                      active: verticalActiveTabWithIcon === "5",
                    })}
                    onClick={() => {
                      toggleVerticalIcon("5");
                    }}
                  >
                    <i className="bx bx-customize"></i>
                    <span className="d-none d-md-block">Custom</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={verticalActiveTabWithIcon}>
                <TabPane tabId="1">
                  <Facebook />
                </TabPane>
                <TabPane tabId="2">
                  <Twitter />
                </TabPane>
                <TabPane tabId="3">
                  <Twitch />
                </TabPane>
                <TabPane tabId="4">
                  <YouTube />
                </TabPane>
                <TabPane tabId="5">
                  <Custom />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Republish;
