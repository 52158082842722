import React from "react";
import UserServiceSidebar from "./ServiceData/UserServiceSidebar";

const ServiceDetails = () => {
  return (
    <React.Fragment>
      <h1>Service Details</h1>
      <UserServiceSidebar />
    </React.Fragment>
  );
};

export default ServiceDetails;
