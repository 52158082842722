import React, { useState } from "react";
import Routers from "./Routes/";
import "./assets/scss/theme.scss";
import { AuthContext } from "./helpers/AuthContext";
import { Route, Routes } from "react-router-dom";

function App() {
  const [authState, setAuthState] = useState({
    email: "",
    id: "",
    status: false,
  });

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Routes>
       <Route path="*" element={<Routers />} />
      </Routes>
    </AuthContext.Provider>
  );
}

export default App;
