import React, { useState, useEffect, useRef } from "react";
import Hls from "hls.js";
import axios from "axios";
import { useParams } from "react-router-dom";

const HLSPlayer = () => {
  const { id } = useParams();
  const [streamUrl, setStreamUrl] = useState("");
  const [serviceStatus, setServiceStatus] = useState("Offline");

  const videoRef = useRef();

  useEffect(() => {
    const fetchStreamUrl = async () => {
      try {
        const response = await axios.get(
          `http://195.87.127.148:3001/streams/${id}`
        );
        const application = response.application;
        const stream = response.stream;
        const url = `195.87.127.140:8081/${application}/${stream}`;
        setStreamUrl(url);

        setServiceStatus("Online");
      } catch (error) {
        setServiceStatus("Offline");
      }
    };
    fetchStreamUrl();
  }, []);

  useEffect(() => {
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(streamUrl);
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        videoRef.current.play();
      });
    } else if (videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
      videoRef.current.src = streamUrl;
      videoRef.current.play();
    }
  }, [streamUrl]);

  return (
    <div className="content">
      <h1>HLS Player</h1>
      <video
        ref={videoRef}
        controls
        style={{ width: "100%", height: "100%" }}
        autoPlay
      />
      <p>Service status: {serviceStatus}</p>
    </div>
  );
};

export default HLSPlayer;
