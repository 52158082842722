import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import axios from "axios";
import ServiceDetails from "../Pages/Services/UserServicesDetails";

const PrivateRoute = () => {
  const { id } = useParams();
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      axios
        .get("http://195.87.127.148:3001/auth/auth", {
          headers: {
            accessToken: accessToken,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setUserId(response.id);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);
  if (userId && userId.toString() !== id) {
    return <Navigate to="/services" />;
  }

  return <ServiceDetails />;
};

export default PrivateRoute;
