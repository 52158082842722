import React, { useState, useEffect, Component } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Card, CardBody, Col, Row, CardTitle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifi, faBroadcastTower } from "@fortawesome/free-solid-svg-icons";

const Cards = () => {
  const { id } = useParams();
  const [listOfCustomers, setListOfCustomers] = useState({
    filteredData1: [],
    filteredData2: [],
  });
  const [server_id, setServer_id] = useState("");
  const [playerUrl, setPlayerUrl] = useState("");
  const [client_id, setClient_id] = useState("");
  const [salt, setSalt] = useState("");
  const [hash, setHash] = useState("");
  const [republishList, setRepublishList] = useState([]);
  const [checkStatus, setCheckStatus] = useState([]);
  const [appName, setAppName] = useState("");
  const [api_key, setApi_key] = useState("");
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [userId, setUserId] = useState("");

  const [authState, setAuthState] = useState({
    id: "",
    userId: "",
    role: "",
  });

  useEffect(() => {
    axios
      .get("http://195.87.127.148:3001/auth/auth", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.error) {
          setAuthState((prevState) => ({ ...prevState, status: false }));
        } else {
          setAuthState({
            id: response.id,
            userId: response.id,
            role: response.role,
            username: response.username,
          });
          setUserId(response.id);
          setRole(response.role);
          setUsername(response.username);
        }
      })
      .catch((error) => {
        console.error("Auth error:", error);
      });
  }, []);

  const fetchServerInfo = () => {
    const currentServer = localStorage.getItem("currentServer");
    if (currentServer) {
      const serverInfo = JSON.parse(currentServer);
      setServer_id(serverInfo.server_id);
      setClient_id(serverInfo.client_id);
      setApi_key(serverInfo.api_key);
      setSalt(serverInfo.salt);
      setHash(serverInfo.hash);
    }
  };

  useEffect(() => {
    if (server_id && client_id && api_key) {
      const storedData = localStorage.getItem("playerUrl");

      if (storedData) {
        try {
          const trimmedData = storedData.trim();
          const parsedData = JSON.parse(trimmedData);
          if (parsedData.server_id === server_id) {
            setPlayerUrl(parsedData.playerUrl);
            return;
          }
        } catch (error) {
          console.error("Error parsing stored data:", error);
        }
      }

      axios
        .get(
          `https://api.wmspanel.com/v1/server/${server_id}?client_id=${client_id}&api_key=${api_key}`
        )
        .then((response) => {
          const data = response;
          if (data && data.server && data.server.ip && data.server.ip[0]) {
            const playerUrl = data.server.ip[0];
            setPlayerUrl(playerUrl);
            localStorage.setItem(
              "playerUrl",
              JSON.stringify({ server_id, playerUrl })
            );
          } else {
            setPlayerUrl();
          }
        })
        .catch((error) => {
          console.error("Error fetching player URL:", error);
        });
    }
  }, [server_id, client_id, api_key]);

  useEffect(() => {
    fetchServerInfo();
  }, []);

  const fetchRepublishList = () => {
    axios
      .get("http://195.87.127.148:3001/republish")
      .then((response) => {
        const data = response;

        if (data) {
          setRepublishList(data);
        } else {
          setRepublishList([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching republish data from local API:", error);
      });
  };

  useEffect(() => {
    fetchRepublishList();
  }, [server_id, client_id, api_key]);

  const fetchData = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        throw new Error("User ID not found in localStorage.");
      }

      const streamsResponse = await axios.get(
        "http://195.87.127.148:3001/streams",
        {
          params: { userId },
          headers: { "Content-Type": "application/json" },
        }
      );

      const filterStream = JSON.parse(localStorage.getItem("currentServer"));
      if (!filterStream) {
        throw new Error("Current server not found in localStorage.");
      }

      const filters = filterStream.filter
        .split(",")
        .map((filter) => filter.trim());

      setAppName(filters);

      if (!filters || filters.length === 0) {
        throw new Error("Filters not found or empty in current server data.");
      }

      const filteredData = streamsResponse.filter((stream) => {
        return filters.some((filter) =>
          stream.application.toLowerCase().includes(filter.toLowerCase())
        );
      });

      const filteredDataByFilter = {};
      filters.forEach((filter) => {
        filteredDataByFilter[filter] = filteredData.filter((stream) =>
          stream.application.toLowerCase().includes(filter.toLowerCase())
        );
      });

      const filteredData1 = [];
      const filteredData2 = [];

      Object.values(filteredDataByFilter).forEach((filterData, index) => {
        if (index % 2 === 0) {
          filteredData1.push(...filterData);
        } else {
          filteredData2.push(...filterData);
        }
      });

      setListOfCustomers({
        filteredData1,
        filteredData2,
      });

      if (filteredData.length === 0) {
      }
    } catch (err) {
      console.error("Error fetching data: ", err);
    } finally {
    }
  };

  useEffect(() => {
    const nimbleStatus = async () => {
      try {
        const response = await axios.get(
          `http://${playerUrl}:8082/manage/live_streams_status?salt=${salt}&hash=${hash}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setCheckStatus(response || []);
      } catch (error) {
        setCheckStatus([]);
      }
    };

    const fetchNimbleStatus = async () => {
      await nimbleStatus();
      await fetchData();
    };
    fetchNimbleStatus();
    const intervalId = setInterval(fetchNimbleStatus, 10000);

    return () => clearInterval(intervalId);
  }, [userId, playerUrl, salt, hash]);

  // Bu sayfa toplam stream sayısını, yayında olan stream sayısını ve yayında olmayan stream sayısını gösterir.

  return (
    <>
      <Row>
        {/* Total Streams */}
        <Col lg="4" md="6" sm="12" xs="12" className="mb-3">
          <Card className="card-stats h-100">
            <CardBody>
              <Row className="align-items-center">
                <Col xs="4">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-tile-56 text-warning" />
                  </div>
                </Col>
                <Col xs="8">
                  <div className="numbers">
                    <p className="card-category">
                      <FontAwesomeIcon
                        icon={faBroadcastTower}
                        style={{ color: "orange" }}
                        beatFade
                      />{" "}
                      Total Streams
                    </p>
                    <CardTitle tag="p">
                      {listOfCustomers.filteredData1.length +
                        listOfCustomers.filteredData2.length}
                    </CardTitle>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        {/* Online Streams */}
        <Col lg="4" md="6" sm="12" xs="12" className="mb-3">
          <Card className="card-stats h-100">
            <CardBody>
              <Row className="align-items-center">
                <Col xs="4">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-chart-bar-32 text-success" />
                  </div>
                </Col>
                <Col xs="8">
                  <div className="numbers">
                    <p className="card-category">
                      <FontAwesomeIcon
                        icon={faWifi}
                        style={{ color: "green" }}
                        beatFade
                      />{" "}
                      Online Streams
                    </p>
                    <CardTitle tag="p">
                      {
                        listOfCustomers.filteredData1
                          .concat(listOfCustomers.filteredData2)
                          .filter((service) => {
                            const matchingApp = checkStatus.find(
                              (stream) => stream.app === service.application
                            );
                            const matchingStream = matchingApp
                              ? matchingApp.streams.find(
                                  (s) => s.strm === service.stream
                                )
                              : null;
                            return (
                              !!matchingStream || service.status === "online"
                            );
                          }).length
                      }
                    </CardTitle>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        {/* Offline Streams */}
        <Col lg="4" md="6" sm="12" xs="12" className="mb-3">
          <Card className="card-stats h-100">
            <CardBody>
              <Row className="align-items-center">
                <Col xs="4">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-simple-remove text-danger" />
                  </div>
                </Col>
                <Col xs="8">
                  <div className="numbers">
                    <p className="card-category">
                      <FontAwesomeIcon
                        icon={faWifi}
                        style={{ color: "red" }}
                        beatFade
                      />{" "}
                      Offline Streams
                    </p>
                    <CardTitle tag="p">
                      {listOfCustomers.filteredData1.length +
                        listOfCustomers.filteredData2.length -
                        listOfCustomers.filteredData1
                          .concat(listOfCustomers.filteredData2)
                          .filter((service) => {
                            const matchingApp = checkStatus.find(
                              (stream) => stream.app === service.application
                            );
                            const matchingStream = matchingApp
                              ? matchingApp.streams.find(
                                  (s) => s.strm === service.stream
                                )
                              : null;
                            return (
                              !!matchingStream || service.status === "online"
                            );
                          }).length}
                    </CardTitle>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Cards;
