import React from "react";
import ServiceSidebar from "./ServiceData/ServiceSidebar";
import { Container } from "reactstrap";


const ServiceDetails = () => {
  return (
    <React.Fragment>
      <Container className="page-content" fluid>
        <h1>Service Details</h1>
      </Container>
      <ServiceSidebar />
    </React.Fragment>
  );
};

export default ServiceDetails;
