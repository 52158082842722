import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { REGISTER_USER } from "./actionTypes";
import { registerUserSuccessful, registerUserFailed } from "./actions";

import axios from "axios";

function* registerUser({ payload: { user } }) {
  try {
    const response = yield call(axios.post, "http://195.87.127.148:3001/auth", {
      email: user.email,
      password: user.password,
    });
    yield put(registerUserSuccessful(response));
  } catch (error) {
    yield put(registerUserFailed(error));
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
}

function* accountSaga() {
  yield all([fork(watchUserRegister)]);
}

export default accountSaga;
