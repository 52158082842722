import React from "react";
import GenerateHashPage from "../GenerateHashPage";

function Hash() {
  return (
    <div className="Hash">
      <GenerateHashPage />
    </div>
  );
}

export default Hash;
