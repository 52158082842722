import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import axios from "axios"; // axios import edildi

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions";

const API_URL = "http://195.87.127.148:3001"; // Kendi API'nizin URL'sini buraya girin

// Kendi API'nizden veri almak için bir fonksiyon oluşturalım
const forgetPasswordAPI = async (email) => {
  try {
    const response = await axios.post(`${API_URL}/forget-password`, { email }); // Kendi endpoint'inizi buraya girin
    return response;
  } catch (error) {
    console.error("API request failed", error);
    throw error;
  }
};

function* forgetUser({ payload: { user, history } }) {
  try {
    const response = yield call(forgetPasswordAPI, user.email);
    if (response) {
      yield put(
        userForgetPasswordSuccess(
          "Reset link are sended to your mailbox, check there first"
        )
      );
    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
