import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

import axios from "axios";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(
      axios.post,
      "http://195.87.127.148:3001/auth/login",
      {
        email: user.email,
        password: user.password,
      }
    );
    yield put(loginSuccess(response));
    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser() {
  try {
    localStorage.removeItem("authUser");
    const response = yield call(axios.post, "http://195.87.127.148:3001/auth");
    yield put(logoutUserSuccess(LOGOUT_USER, response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
