import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";
import axios from "axios";

function CustomerDetails() {
  const { id } = useParams();
  const [customer, setCustomer] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const navigate = useNavigate();

  const handleLoginAsUser = async () => {
    try {
      // Send a request to your backend to generate a new JWT for this user
      const response = await axios.post(
        `http://195.87.127.148:3001/auth/${id}/login-as-user`
      );
      setSuccessMessage("Login as user successful.");
      // Save the JWT to local storage
      localStorage.setItem("accessToken", response.token);
      // windows refresh page
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setErrorMessage("User ID not found.");
      } else {
        setErrorMessage("Login as user is not available right now.");
      }
    }
  };

  const handleEmailUser = () => {
    // Open the user's default email client with a pre-filled "To" address
    window.location.href = `mailto:${customer.email}`;
  };

  const handleCreateService = () => {
    // Redirect to a new service creation page, potentially passing the customer ID
    navigate(`/admin-services/new/${customer.id}`);
  };

  const handleService = () => {
    // Redirect to a new service creation page, potentially passing the customer ID
    navigate(`/admin-services/${customer.id}`);
  };

  const handleResetPassword = () => {
    navigate(`/admin-reset-password/${customer.id}`);
  };

  const handleDeleteUser = async () => {
    try {
      // Send a DELETE request to your backend to delete the user
      await axios.delete(`http://195.87.127.148:3001/customer/${id}`);
      setSuccessMessage("User deleted successfully.");
      // Optionally, redirect to a different page after successful deletion
      navigate("/admin-customer");
    } catch (error) {
      setErrorMessage("Error deleting user.");
    }
  };
  useEffect(() => {
    // Fetch data from the backend API
    axios
      .get(`http://195.87.127.148:3001/customer/${id}`)
      .then((response) => setCustomer(response))
      .catch((error) => console.error("Error fetching customer:", error));
  }, []); // Fetch data whenever 'id' changes

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Customer" breadcrumbItem="Customer Details" />

        <div>
          <Button color="warning" size="sm" onClick={handleLoginAsUser}>
            <i className="fa fa-sign-in-alt"></i> Login as this user
          </Button>
          <Button color="primary" size="sm" onClick={handleEmailUser}>
            <i className="fa fa-envelope"></i> Email this user
          </Button>
          {/* ... (Modify Account Details button - same as before) ... */}
          <Button color="info" size="sm" onClick={handleResetPassword}>
            <i className="fa fa-key"></i> Reset &amp; Mail Password
          </Button>
          <Button color="success" size="sm" onClick={handleCreateService}>
            <i className="fa fa-sitemap"></i> Create Service
          </Button>
          <Button
            color="danger"
            size="sm"
            className="float-end"
            onClick={() => setShowDeleteConfirmation(true)}
          >
            <i className="fa fa-times"></i> Delete
          </Button>
        </div>
      </div>

      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <img
                    src={customer.image}
                    alt="Customer"
                    className="rounded-circle float-end"
                    width="100"
                    height="100"
                  />
                  <h4 className="card-title">Customer Details</h4>
                  <p className="card-title-desc">
                    View and edit customer details.
                  </p>
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <Button color="primary" size="sm">
                            <i className="fa fa-id-card"></i> ID
                          </Button>
                        </td>
                        <td>{customer.id}</td>
                      </tr>
                      <tr>
                        <td>
                          <Button color="primary" size="sm">
                            <i className="fa fa-user"></i> Customer
                          </Button>
                        </td>
                        <td>
                          {customer.firstName} {customer.lastName}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Button color="primary" size="sm">
                            <i className="fa fa-envelope"></i> Email
                          </Button>
                        </td>
                        <td>{customer.email}</td>
                      </tr>
                      <tr>
                        <td>
                          <Button color="primary" size="sm">
                            <i className="fa fa-phone"></i> Phone
                          </Button>
                        </td>
                        <td>{customer.number}</td>
                      </tr>
                      <tr>
                        <td>
                          <Button color="primary" size="sm">
                            <i className="fa fa-map-marker"></i> Services
                          </Button>
                        </td>
                        <td>{customer.service}</td>
                      </tr>
                      <tr>
                        <td>
                          <Button color="primary" size="sm">
                            <i className="fa fa-hdd"></i> Storage
                          </Button>
                        </td>
                        <td>{customer.storage}</td>
                      </tr>
                      <tr>
                        <td>
                          <Button color="primary" size="sm">
                            <i className="fa fa-check"></i> Active
                          </Button>
                        </td>
                        <td>{customer.active}</td>
                      </tr>
                      <tr>
                        <td>
                          <Button color="danger" size="sm">
                            <i className="fa fa-ban"></i> Suspend
                          </Button>
                        </td>
                        <td>{customer.suspend}</td>
                      </tr>

                      <tr>
                        <td>
                          <Button color="primary" size="sm">
                            <i className="fa fa-user"></i> Type
                          </Button>
                        </td>
                        <td>{customer.type}</td>
                      </tr>
                      <tr>
                        <td>
                          <Button color="primary" size="sm">
                            <i className="fa fa-upload"></i> Publish
                          </Button>
                        </td>
                        <td>{customer.publish}</td>
                      </tr>
                      <tr>
                        <td>
                          <Button color="primary" size="sm">
                            <i className="fas fa-code-branch"></i> Connections
                          </Button>
                        </td>
                        <td>{customer.connections}</td>
                      </tr>
                      <tr>
                        <td>
                          <Button color="primary" size="sm">
                            <i className="fa fa-signal"></i> Bitrate
                          </Button>
                        </td>
                        <td>{customer.bitrate}</td>
                      </tr>
                      <tr>
                        <td>
                          <Button color="primary" size="sm">
                            <i
                              className=" fas fa-network-wired
"
                            ></i>{" "}
                            Bandwidth
                          </Button>
                        </td>
                        <td>{customer.band}</td>
                      </tr>
                      <tr>
                        <td>
                          <Button color="primary" size="sm">
                            <i className="fas fa-box-open"></i> Band Storage
                          </Button>
                        </td>
                        <td>{customer.bandstorage}</td>
                      </tr>
                      <tr>
                        <td>
                          <Button color="primary" size="sm">
                            <i className="fa fa-sitemap"></i> Status
                          </Button>
                        </td>
                        <td>{customer.status}</td>
                      </tr>
                      <tr>
                        <td>
                          <Button
                            color="primary"
                            size="sm"
                            onClick={handleService}
                          >
                            <i className="fa fa-sitemap"></i> Service
                          </Button>
                        </td>
                        <td>{customer.url}</td>
                      </tr>
                      <tr>
                        <td>
                          <Button color="primary" size="sm">
                            <i className="fa fa-key"></i> Stream Key
                          </Button>
                        </td>
                        <td>{customer.key}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={showDeleteConfirmation}
        toggle={() => setShowDeleteConfirmation(false)}
      >
        <ModalHeader toggle={() => setShowDeleteConfirmation(false)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete this user? This action cannot be
          undone.
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDeleteUser}>
            Delete
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* Success/Error Message Alert */}
      {successMessage && <Alert color="primary">{successMessage}</Alert>}
      {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
    </React.Fragment>
  );
}

export default CustomerDetails;
