import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Button, Card, CardBody, Col, Container, Row, Alert } from "reactstrap";

import logodark from "../../assets/images/logo-dark.png";
import darkbg from "../../assets/images/bg.jpg";

function SetCreatedByField({ username }) {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue("createdBy", username);
  }, [username, setFieldValue]);

  return null;
}

function CreateService() {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [username, setUsername] = useState("");
  const initialValues = {
    firstName: "",
    lastName: "",
    level: "",
    email: "",
    number: "",
    service: "",
    storage: "",
    image: "",
    active: "",
    suspend: "",
    type: "",
    publish: "",
    connections: "",
    bitrate: "",
    band: "",
    status: "",
    url: "",
    key: "",
    username: "",
    password: "",
    createdBy: "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().min(3).max(15).required("Ad alanı zorunludur"),
    lastName: Yup.string().min(3).max(15).required("Soyad alanı zorunludur"),
    level: Yup.string().required("Seviye alanı zorunludur"),
    email: Yup.string()
      .email("Geçerli bir e-posta adresi girin")
      .required("E-posta alanı zorunludur"),
    number: Yup.string()
      .min(10)
      .max(13)
      .required("Telefon numarası alanı zorunludur"),
    service: Yup.number().required("Servis alanı zorunludur"),
    storage: Yup.number().required("Depolama alanı zorunludur"),
    image: Yup.string(), // image alanı opsiyonel yapıldı
    active: Yup.string().required("Aktif alanı zorunludur"),
    suspend: Yup.string().required("Askıya alınan alanı zorunludur"),
    type: Yup.string().required(),
    publish: Yup.string().required(),
    connections: Yup.string().required(),
    bitrate: Yup.string().required(),
    band: Yup.string().required(),
    status: Yup.string().required(),
    url: Yup.string().required(),
    key: Yup.string().required(),
    username: Yup.string().required(),
    password: Yup.string().required(),
    createdBy: Yup.string().required(),
  });

  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      axios
        .get("http://195.87.127.148:3001/auth/auth", {
          headers: { accessToken },
        })
        .then((response) => {
          setUsername(response.username);
        });
    }
  }, []);

  const onSubmit = async (values) => {
    try {
      values.createdBy = username;
      const response = await axios.post(
        "http://195.87.127.148:3001/customer/createservice",
        values
      );
      setSubmitSuccess(true);
      setTimeout(() => {
        navigate("/admin-services");
      }, 5000); // 5 saniye sonra yönlendir
    } catch (error) {
      setSubmitError(error.response || "Service Creation Failed");
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          backgroundImage: `url(${darkbg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "auto",
          width: "100%",
        }}
      >
        <Container>
          <Row className="justify-content-center p-5 m-5 ">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div>
                        <Link to="/">
                          <img src={logodark} alt="" height="20" />
                        </Link>
                      </div>
                    </div>
                    <div className="p-2">
                      <h5 className="text-center">Create Service</h5>
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                      >
                        {() => (
                          <Form>
                            <SetCreatedByField username={username} />
                            <div className="mb-3">
                              <label htmlFor="firstName" className="form-label">
                                First Name
                              </label>
                              <Field
                                type="text"
                                name="firstName"
                                id="firstName"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="firstName"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="lastName" className="form-label">
                                Last Name
                              </label>
                              <Field
                                type="text"
                                name="lastName"
                                id="lastName"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="lastName"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="level" className="form-label">
                                Level
                              </label>
                              <Field
                                type="text"
                                name="level"
                                id="level"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="level"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="email" className="form-label">
                                Email
                              </label>
                              <Field
                                type="email"
                                name="email"
                                id="email"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="number" className="form-label">
                                Number
                              </label>
                              <Field
                                type="text"
                                name="number"
                                id="number"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="number"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="service" className="form-label">
                                Service
                              </label>
                              <Field
                                type="number"
                                name="service"
                                id="service"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="service"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="storage" className="form-label">
                                Storage
                              </label>
                              <Field
                                type="number"
                                name="storage"
                                id="storage"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="storage"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="image" className="form-label">
                                Image
                              </label>
                              <Field
                                type="text"
                                name="image"
                                id="image"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="image"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="active" className="form-label">
                                Active
                              </label>
                              <Field
                                type="text"
                                name="active"
                                id="active"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="active"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="suspend" className="form-label">
                                Suspend
                              </label>
                              <Field
                                type="text"
                                name="suspend"
                                id="suspend"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="suspend"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="type" className="form-label">
                                Type
                              </label>
                              <Field
                                type="text"
                                name="type"
                                id="type"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="type"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="publish" className="form-label">
                                Publish
                              </label>
                              <Field
                                type="text"
                                name="publish"
                                id="publish"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="publish"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="connections"
                                className="form-label"
                              >
                                Connections
                              </label>
                              <Field
                                type="text"
                                name="connections"
                                id="connections"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="connections"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="bitrate" className="form-label">
                                Bitrate
                              </label>
                              <Field
                                type="text"
                                name="bitrate"
                                id="bitrate"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="bitrate"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="band" className="form-label">
                                Band
                              </label>
                              <Field
                                type="text"
                                name="band"
                                id="band"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="band"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="status" className="form-label">
                                Status
                              </label>
                              <Field
                                type="text"
                                name="status"
                                id="status"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="status"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="url" className="form-label">
                                Url
                              </label>
                              <Field
                                type="text"
                                name="url"
                                id="url"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="url"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="key" className="form-label">
                                Key
                              </label>
                              <Field
                                type="text"
                                name="key"
                                id="key"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="key"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="username" className="form-label">
                                Username
                              </label>
                              <Field
                                type="text"
                                name="username"
                                id="username"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="username"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="password" className="form-label">
                                Password
                              </label>
                              <Field
                                type="password"
                                name="password"
                                id="password"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="createdBy" className="form-label">
                                Created By
                              </label>
                              <Field
                                type="text"
                                name="createdBy"
                                id="createdBy"
                                className="form-control"
                                value={username}
                                readOnly
                              />
                              <ErrorMessage
                                name="createdBy"
                                component="div"
                                className="text-danger"
                              />
                            </div>

                            <div className="mt-3 d-grid">
                              <Button type="submit" color="primary">
                                Create Service
                              </Button>
                            </div>
                            {submitSuccess && (
                              <Alert color="success" className="mt-3">
                                Service created successfully.
                              </Alert>
                            )}
                            {submitError && (
                              <Alert color="danger" className="mt-3">
                                {submitError}
                              </Alert>
                            )}
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
export default CreateService;
