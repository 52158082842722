import React from "react";

import img1 from "../../assets/images/logo-dark.png";

import { Container, Row, Col, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

const Maintenance = () => {
  return (
    <React.Fragment>
      <div className="py-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={8} xl={5}>
              <div className="text-center mt-sm-5 mb-4">
                <Link to="/" className="">
                  <img
                    src={img1}
                    alt=""
                    height="48"
                    className="auth-logo logo-dark mx-auto"
                  />
                </Link>
                <p className="mt-3">Veriyum Admin Dashboard</p>

                <div className="mt-5">
                  <div className="mb-4">
                    <i className="ri-tools-fill display-3"></i>
                  </div>
                  <h4>This Page is Under Maintenance</h4>
                  <p>Please check back in sometime</p>

                  <div className="mt-4 pt-2">
                    <Link to="/" className="btn btn-primary">
                      Back to Home
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mt-4 p-4">
            <Col md={12}>
              <div className="text-center">
                <CardBody>
                  <div className="avatar-sm mx-auto mb-4">
                    <div className="avatar-title rounded-circle">
                      <i className="ri-mail-line font-size-24"></i>
                    </div>
                  </div>
                  <h5 className="font-size-15 text-uppercase">
                    Do you need Support ?
                  </h5>
                  <p className="mb-0">
                    Plaease feel free to contact us if you have any questions.{" "}
                    {""} <br />
                    <Link
                      to="mailto:veriyum@veriyum.com"
                      className="text-decoration-underline"
                    >
                      veriyum@veriyum.com
                    </Link>
                  </p>
                </CardBody>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Maintenance;
