import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import CryptoJS from "crypto-js";

const GetServer = () => {
  const [servers, setServers] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [decryptedApiKey, setDecryptedApiKey] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserId();
  }, []);

  const fetchUserId = async () => {
    try {
      let userId = localStorage.getItem("userId");

      if (!userId) {
        const response = await axios.get(
          `http://195.87.127.148:3001/auth/auth/`,
          {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          }
        );

        userId = response.id;

        if (userId) {
          localStorage.setItem("userId", userId);
          fetchData(userId);
        } else {
          throw new Error("User ID not found in response.");
        }
      } else {
        fetchData(userId);
      }
    } catch (error) {
      console.error("Error fetching user ID:", error);
      setError("Failed to fetch user ID.");
    }
  };

  const fetchData = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        throw new Error("User ID not found in localStorage.");
      }

      const response = await axios.get(
        `http://195.87.127.148:3001/server/all`,
        {
          params: { userId },
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = response;
      setServers(data);

      if (data.length > 0) {
        setServerData(data[0]);
        localStorage.setItem("currentServer", JSON.stringify(data[0]));

        const secretKey = await fetchSecretKey(userId);
        if (secretKey) {
          decryptServerData(secretKey, data[0]);
        }
      } else {
        setError("No servers found for the user.");
      }
    } catch (error) {
      console.error("Error fetching server data:", error);
      setError("Failed to fetch server data.");
    }
  };

  const fetchSecretKey = async (userId) => {
    try {
      const response = await axios.get(`http://195.87.127.148:3001/auth/auth`, {
        params: { userId },
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      });
      return response.secretKey;
    } catch (error) {
      console.error("Error fetching secret key:", error);
      setError("Failed to fetch secret key.");
      return null;
    }
  };

  const decryptServerData = (secretKey, server) => {
    try {
      // Decrypt API key
      const decryptedApiKey = CryptoJS.AES.decrypt(
        server.api_key,
        secretKey
      ).toString(CryptoJS.enc.Utf8);
      setDecryptedApiKey(decryptedApiKey);

      // Update current server with decrypted API key
      const updatedServer = { ...server, api_key: decryptedApiKey };
      setServers((prevServers) =>
        prevServers.map((s, index) =>
          index === currentIndex ? updatedServer : s
        )
      );
      localStorage.setItem("currentServer", JSON.stringify(updatedServer));
    } catch (error) {
      console.error("Error decrypting API key:", error);
      setError("Failed to decrypt API key.");
    }
  };

  const setServerData = (server) => {
    // Set server data to state
    setDecryptedApiKey(""); // Clear decrypted API key
    setCurrentIndex(0); // Update current index
  };

  const handleNext = () => {
    const newIndex = (currentIndex + 1) % servers.length;
    setServerData(servers[newIndex]);
  };

  const handlePrevious = () => {
    const newIndex = (currentIndex - 1 + servers.length) % servers.length;
    setServerData(servers[newIndex]);
  };

  return (
    <div>
      <Row>
        <Col lg={6}>
          <Card>
            <CardBody>
              <CardTitle>App Name: {servers[currentIndex]?.app_name}</CardTitle>
              <CardTitle>
                Client ID: {servers[currentIndex]?.client_id}
              </CardTitle>
              <CardTitle>
                Server ID: {servers[currentIndex]?.server_id}
              </CardTitle>
              <CardTitle>Salt: {servers[currentIndex]?.salt}</CardTitle>
              <CardTitle>Hash: {servers[currentIndex]?.hash}</CardTitle>
              <CardTitle>User ID: {servers[currentIndex]?.userId}</CardTitle>

              <FormGroup>
                <Label for="apiKey">API Key:</Label>
                <Input
                  type="text"
                  name="apiKey"
                  id="apiKey"
                  value={servers[currentIndex]?.api_key}
                  disabled
                />
              </FormGroup>

              <FormGroup>
                <Label for="decryptedApiKey">Decrypted API Key:</Label>
                <Input
                  type="text"
                  name="decryptedApiKey"
                  id="decryptedApiKey"
                  value={decryptedApiKey}
                  disabled
                />
              </FormGroup>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  color="secondary"
                  onClick={handlePrevious}
                  disabled={servers.length <= 1}
                >
                  Önceki
                </Button>
                <Button
                  color="secondary"
                  onClick={handleNext}
                  disabled={servers.length <= 1}
                >
                  Sonraki
                </Button>
              </div>

              {error && <p style={{ color: "red" }}>{error}</p>}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default GetServer;
