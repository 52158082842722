/* global SLDP */
import React, { useEffect } from "react";
import { Button } from "reactstrap";

const SLDPPlayer = ({ streamUrl }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://softvelum.com/player/releases/sldp-v2.26.1.min.js";
    script.onload = () => {
      SLDP.init({
        container: "player",
        stream_url: streamUrl,
        adaptive_bitrate: { initial_rendition: "240p" },
        buffering: 500,
        autoplay: false,
        height: "auto",
        width: "auto",
      });
    };
    document.body.appendChild(script);

    return () => {
      const player = document.getElementById("player");
      if (player) {
        player.innerHTML = "";
      }
    };
  }, [streamUrl]);

  const refreshStream = () => {
    const player = document.getElementById("player");
    if (player) {
      player.innerHTML = "";
    }
    SLDP.init({
      container: "player",
      stream_url: streamUrl,
      adaptive_bitrate: { initial_rendition: "240p" },
      buffering: 500,
      autoplay: true,
      height: 480,
      width: 640,
    });
  };

  const stopStream = () => {
    const player = document.getElementById("player");
    if (player) {
      player.innerHTML = "";
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <Button
            color="primary"
            size="sm"
            className="me-2"
            onClick={refreshStream}
          >
            <i className="fa fa-sync"></i> Refresh
          </Button>
          <Button
            color="primary"
            size="sm"
            className="me-2"
            onClick={stopStream}
          >
            <i className="fa fa-stop"></i> Stop
          </Button>
        </div>
      </div>
      <div id="player" className="mb-3"></div>
    </div>
  );
};

export default SLDPPlayer;
