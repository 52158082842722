import React from "react";
import ServiceSidebar from "./ServiceData/ServiceSidebar";
import { Container } from "reactstrap";

const ServiceDetails = () => {
  return (
    <React.Fragment>
      <Container className="justify-content-center page-content" fluid>
        <ServiceSidebar />
      </Container>
    </React.Fragment>
  );
};

export default ServiceDetails;
