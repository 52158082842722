const SidebarData = [
  {
    label: "Menu",
    isMainMenu: true,
  },
  {
    label: "Dashboard",
    icon: "mdi mdi-home-variant-outline",
    url: "/admin-dashboard",
    issubMenubadge: true,
  },
  {
    label: "Server",
    icon: "mdi mdi-server",
    url: "/admin-server",
    issubMenubadge: true,
  },
  {
    label: "Customer",
    icon: "mdi mdi-account-group-outline",
    url: "/admin-customer",
    issubMenubadge: true,
  },
  {
    label: "Services",
    icon: "mdi mdi-lan",
    url: "/admin-services",
    issubMenubadge: true,
  },
  {
    label: "Republishes",
    icon: "mdi mdi-refresh",
    url: "/admin-republish",
    issubMenubadge: true,
  },
  {
    label: "Statistics",
    icon: "mdi mdi-chart-line",
    url: "/admin-statistic",
    issubMenubadge: true,
  },
  {
    label: "Admistartion",
    icon: "mdi mdi-cog",
    subItem: [
      { sublabel: "System Config", link: "/admin-config" },
      { sublabel: "Reseller Plan", link: "/admin-reseller-plan" },
      { sublabel: "Email Template", link: "/admin-email-template" },
      { sublabel: "Announcements", link: "/admin-announcements" },
      { sublabel: "Software Health", link: "/admin-software-health" },
      { sublabel: "Software Update", link: "/admin-software-update" },
    ],
  },
  {
    label: "Profile",
    icon: "mdi mdi-account-circle",
    url: "/admin-profile",
    issubMenubadge: true,
  },
];

export default SidebarData;
