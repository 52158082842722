/* global SLDP */
import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap"; // Bootstrap Container, Row ve Col bileşenlerini import ediyoruz

const SLDPPlayer = ({ streamUrl, height, width }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://softvelum.com/player/releases/sldp-v2.26.1.min.js";
    document.body.appendChild(script);

    let player;

    script.onload = () => {
      player = SLDP.init({
        container: "player",
        stream_url: streamUrl,
        adaptive_bitrate: {
          initial_rendition: "480p",
        },
        height: height,
        width: width
      });
    };

    return () => {
      if (player) {
        player.destroy();
      }

      const playerElement = document.getElementById("player");
      if (playerElement) {
        playerElement.innerHTML = "";
      }

      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [streamUrl]);

  return (
    <Container>
        <Col xs={12} md={8} lg={6}>
            <div id="player" className="player"></div>
        </Col>
    </Container>
  );
};

export default SLDPPlayer;
