import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";

import { Row, Col, CardBody, Card, Container, Button } from "reactstrap";

import logodark from "../../assets/images/logo-dark.png";
import darkbg from "../../assets/images/bg.jpg";

function CreateUser() {
  const initialValues = {
    username: "",
    email: "",
    password: "",
    number: "",
    adress: "",
    cName: "",
    cAdress: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().min(3).max(20).required(),
    email: Yup.string().email().required(),
    password: Yup.string().min(4).max(20).required(),
    number: Yup.string().min(10).max(13).required(),
    adress: Yup.string().required(),
    cName: Yup.string().required(),
    cAdress: Yup.string().required(),
  });

  const navigate = useNavigate();

  const onSubmit = (data) => {
    axios
      .post("http://195.87.127.148:3001/auth", data)
      .then(() => {
        alert("Registered Successfully");
        navigate("/", 5000);
      })
      .catch((error) => {
        alert("This email is already registered.");
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <div
        style={{
          backgroundImage: `url(${darkbg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="fas fa-home h2"></i>
          </Link>
        </div>
        <div className="bg-overlay"></div>
        <div className="account-pages pt-4 ">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6} md={8} xl={4}>
                <Card className="mt-5">
                  <CardBody className="p-4">
                    <div className="text-center">
                      <Link to="/" className="">
                        <img
                          src={logodark}
                          alt=""
                          height="24"
                          className="auth-logo logo-dark mx-auto"
                        />
                      </Link>
                    </div>

                    <h4 className="font-size-18 text-muted text-center mt-2">
                      Register
                    </h4>
                    <p className="text-muted text-center mb-4">
                      Get customer account now.
                    </p>
                    <div className="p-2">
                      <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                      >
                        <Form className="form-horizontal">
                          <div className="mb-3">
                            <Field
                              name="username"
                              type="text"
                              className="form-control"
                              placeholder="Enter username"
                            />
                            <ErrorMessage
                              name="username"
                              component="small"
                              className="text-danger"
                            />
                          </div>

                          <div className="mb-3">
                            <Field
                              name="email"
                              type="email"
                              className="form-control"
                              placeholder="Enter email"
                            />
                            <ErrorMessage
                              name="email"
                              component="small"
                              className="text-danger"
                            />
                          </div>

                          <div className="mb-3">
                            <Field
                              name="password"
                              type="password"
                              className="form-control"
                              placeholder="Enter password"
                            />
                            <ErrorMessage
                              name="password"
                              component="small"
                              className="text-danger"
                            />
                          </div>

                          <div className="mb-3">
                            <Field
                              name="number"
                              type="text"
                              className="form-control"
                              placeholder="Enter number"
                            />
                            <ErrorMessage
                              name="number"
                              component="small"
                              className="text-danger"
                            />
                          </div>
                          <div className="mb-3">
                            <Field
                              name="adress"
                              type="text"
                              className="form-control"
                              placeholder="Enter adress"
                            />
                            <ErrorMessage
                              name="adress"
                              component="small"
                              className="text-danger"
                            />
                          </div>

                          <div className="mb-3">
                            <Field
                              name="cName"
                              type="text"
                              className="form-control"
                              placeholder="Enter company name"
                            />
                            <ErrorMessage
                              name="cName"
                              component="small"
                              className="text-danger"
                            />
                          </div>

                          <div className="mb-3">
                            <Field
                              name="cAdress"
                              type="text"
                              className="form-control"
                              placeholder="Enter company adress"
                            />
                            <ErrorMessage
                              name="cAdress"
                              component="small"
                              className="text-danger"
                            />
                          </div>

                          <div className="mt-4">
                            <Button color="primary" type="submit" block>
                              Register
                            </Button>
                          </div>
                        </Form>
                      </Formik>
                      <div className="mt-5 text-center">
                        <p className="text-mute">
                          © {new Date().getFullYear()} Veriyum. All rights
                          reserved.
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CreateUser;
