import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import withRouter from "../withRouter";

import axios from "axios";

const ProfileMenu = (props) => {
  const [username, setUsername] = useState("");
  const [image, setImage] = useState("");
  const [menu, setMenu] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [role, setRole] = useState("");

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      axios
        .get("http://195.87.127.148:3001/auth/auth", {
          headers: {
            accessToken: accessToken,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setUsername(response.username);
          setImage(response.image);
          setRole(response.role);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  const logout = () => {
    localStorage.clear();
    localStorage.clear();
    window.location.href = "/login";
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={image}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">
            {username}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem tag="a" href="/admin-profile">
            <i className="ri-user-line align-middle me-1"></i>{" "}
            {props.t("View Profile")}
          </DropdownItem>

          <div className="dropdown-divider"></div>
          <DropdownItem onClick={logout}>
            <i className="ri-logout-circle-r-line align-middle me-1"></i>{" "}
            {props.t("Logout")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
