import React from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useState } from "react";
import { Dash, GetServer } from "./serverindex";

const Dashboard = () => {
  const [activeTab, setactiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  return (
    <>
      <Row className="page-content">
        <Col xl={12}>
          <Card>
            <CardBody>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{
                      cursor: "pointer",
                    }}
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                    hidden
                  >
                    <i className="fa fa-server me-1 align-middle"> </i> Get
                    Server
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2");
                    }}
                    hidden
                  >
                    <i className="fas fa-wrench align-middle"></i> Create Server
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab} className="p-3">
                <TabPane tabId="1" id="getServer">
                  <Dash />
                </TabPane>
                <TabPane tabId="2" id="updateServer">
                  <GetServer />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
