import React from "react";
import { Navigate } from "react-router-dom";
import PrivateRoute from "../helpers/PrivateRoute.js";

//Dashboard
import Dashboard from "../Pages/Dashboard/index.js";
import DashDetails from "../Pages/Dashboard/DashDetails.js";
import UserDash from "../Pages/Dashboard/userDash.js";
import UserDashDetails from "../Pages/Dashboard/UserDashDetails.js";

import ResellerDashboard from "../Pages/Dashboard/ResellerDash/index.js";
import ResellerDashDetails from "../Pages/Dashboard/ResellerDash/DashDetails.js";

// Import Customer
// import Customer from "../Pages/Customer/AdminCustomer.js";

import Facebook from "../Pages/Services/ServiceData/SidebarData/Overview/Republish/facebook.js";
import Twitch from "../Pages/Services/ServiceData/SidebarData/Overview/Republish/twitch.js";
import Youtube from "../Pages/Services/ServiceData/SidebarData/Overview/Republish/youtube.js";
import Twitter from "../Pages/Services/ServiceData/SidebarData/Overview/Republish/twitter.js";

// import Republish from "../Pages/Republish/Republishes.js";

import ServerInfo from "../Pages/Server/admin/serverinfo.js";
import ResellerServer from "../Pages/Server/reseller/serverinfo.js";

// Import Statistics
// import Statistics from "../Pages/Statistics/Statistics.js";

// import AdminServices from "../Pages/Services/AdminServices.js";

import UserProfile from "../Pages/Authentication/UserProfile.js";
import AdminProfile from "../Pages/Authentication/AdminProfile.js";

import AdminCustomerDetails from "../Pages/Customer/AdminCustomerDetails.js";
import AdminServiceDetails from "../Pages/Services/AdminServiceDetails.js";

import ResellerCustomer from "../Pages/Customer/ResellerCustomer.js";
import ResellerCustomerDetails from "../Pages/Customer/ResellerCustomerDetails.js";

import ResellerServices from "../Pages/Services/ResellerServices.js";
import ResellerServicesDetails from "../Pages/Services/ResellerServiceDetails.js";

import UserService from "../Pages/Services/UserServices.js";
import UserServicesDetails from "../Pages/Services/UserServicesDetails.js";

// Import Authentication pages
import Login from "../Pages/Authentication/Login.js";
import ResetPassword from "../Pages/Authentication/ResetPassword.js";
import Register from "../Pages/Authentication/Register.js";

// Import Utility Pages
import Maintenance from "../Pages/Utility/Maintenance-Page.js";
import Error404 from "../Pages/Utility/Error404-Page.js";
import Error500 from "../Pages/Utility/Error500-Page.js";
import AdminError404 from "../Pages/Utility/Error404-Page-Admin.js";

import CreateUser from "../Pages/Create/CreateUser.js";
import CreateService from "../Pages/Create/CreateService.js";
import LockScreen from "../Pages/Authentication/LockScreen.js";

// Import UIElement Pages
// import SystemConfig from "../Pages/Administration/Config.js";
// import ResellerPlan from "../Pages/Administration/Reseller.js";
// import EmailTemplate from "../Pages/Administration/Email.js";
// import Announcements from "../Pages/Administration/Announcements.js";
// import SoftwareHealth from "../Pages/Administration/Health.js";
// import SoftwareUpdate from "../Pages/Administration/Update.js";

const authProtectedRoutes = [
  //Admin Layout

  //dashboard
  { path: "/admin-dashboard", element: <Dashboard />, role: "admin" },

  { path: "/admin-dashboard/:id", element: <DashDetails />, role: "admin" },

  // Customer
  { path: "/admin-customer", element: <Maintenance />, role: "admin" },

  // Statistics
  { path: "/admin-statistic", element: <Maintenance />, role: "admin" },

  // Customer Profile
  {
    path: "/admin-customer/:id",
    element: <AdminCustomerDetails />,
    role: "admin",
  },

  // Republish
  { path: "/admin-facebook", element: <Facebook />, role: "admin" },
  { path: "/admin-twitch", element: <Twitch />, role: "admin" },
  { path: "/admin-youtube", element: <Youtube />, role: "admin" },
  { path: "/admin-twitter", element: <Twitter />, role: "admin" },

  // Service Profile
  {
    path: "/admin-services/:id",
    element: <AdminServiceDetails />,
    role: "admin",
  },

  // Server
  { path: "/admin-server", element: <ServerInfo />, role: "admin" },

  // Service
  { path: "/admin-services", element: <Maintenance />, role: "admin" },

  // User Profile
  { path: "/admin-profile", element: <AdminProfile />, role: "admin" },

  // SystemConfig Pages
  { path: "/admin-config", element: <Maintenance />, role: "admin" },
  { path: "/admin-reseller-plan", element: <Maintenance />, role: "admin" },
  {
    path: "/admin-email-template",
    element: <Maintenance />,
    role: "admin",
  },
  { path: "/admin-announcements", element: <Maintenance />, role: "admin" },

  { path: "/admin-republish", element: <Maintenance />, role: "admin" },

  { path: "/admin-create-user", element: <CreateUser />, role: "admin" },
  { path: "/admin-create-service", element: <CreateService />, role: "admin" },

  {
    path: "/admin-reset-password/:id",
    element: <ResetPassword />,
    role: "admin",
  },

  {
    path: "/admin-software-health",
    element: <Maintenance />,
    role: "admin",
  },
  {
    path: "/admin-software-update",
    element: <Maintenance />,
    role: "admin",
  },

  { path: "*", element: <AdminError404 />, role: "admin" },

  {
    path: "/",
    exact: true,
    element: <Navigate to="/admin-dashboard" />,
    role: "admin",
  },

  //User Layout

  //dashboard
  { path: "/dashboard", element: <Dashboard />, role: "user" },

  // Service
  { path: "/services", element: <Maintenance />, role: "user" },

  // Service Profile
  {
    path: "/services/:id",
    element: <PrivateRoute component={UserServicesDetails} />,
    role: "user",
  },

  // Republish
  { path: "/facebook", element: <Facebook />, role: "user" },
  { path: "/twitch", element: <Twitch />, role: "user" },
  { path: "/youtube", element: <Youtube />, role: "user" },
  { path: "/twitter", element: <Twitter />, role: "user" },

  // User Profile
  { path: "/profile", element: <UserProfile />, role: "user" },

  { path: "/dashboard/:id", element: <UserDashDetails />, role: "user" },

  { path: "*", element: <Error404 />, role: "user" },

  {
    path: "/",
    exact: true,
    element: <Navigate to="/dashboard" />,
  },

  //Reseller Layout

  //dashboard
  {
    path: "/reseller-dashboard",
    element: <ResellerDashboard />,
    role: "reseller",
  },

  {
    path: "/reseller-dashboard/:id",
    element: <ResellerDashDetails />,
    role: "reseller",
  },

  //server
  { path: "/reseller-server", element: <ResellerServer />, role: "reseller" },

  // Service
  {
    path: "/reseller-services",
    element: <ResellerServices />,
    role: "reseller",
  },

  // User Profile
  { path: "/reseller-profile", element: <UserProfile />, role: "reseller" },

  // Customer
  {
    path: "/reseller-customers",
    element: <ResellerCustomer />,
    role: "reseller",
  },

  // Customer Profile
  {
    path: "/reseller-customer/:id",
    element: <ResellerCustomerDetails />,
    role: "reseller",
  },

  // Service Profile
  {
    path: "/reseller-services/:id",
    element: <ResellerServicesDetails />,
    role: "reseller",
  },

  {
    path: "/reseller-create-service",
    element: <CreateService />,
    role: "reseller",
  },

  { path: "/reseller-create-user", element: <CreateUser />, role: "reseller" },

  { path: "*", element: <Error404 />, role: "reseller" },

  {
    path: "/",
    exact: true,
    element: <Navigate to="/reseller-dashboard" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/login", element: <Login /> },
  { path: "/register", element: <Register /> },
  { path: "/lock-screen", element: <LockScreen /> },
  { path: "/create-user", element: <CreateUser /> },

  // Utility Pages
  { path: "/pages-404", element: <Error404 /> },
  { path: "/pages-500", element: <Error500 /> },
  { path: "/pages-maintenance", element: <Maintenance /> },

  {
    path: "/",
    exact: true,
    element: <Navigate to="/login" />,
  },
];

export { authProtectedRoutes, publicRoutes };
