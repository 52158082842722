import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useParams } from "react-router-dom";
import axios from "axios";

function Facebook() {
  const { id } = useParams();
  const [service, setService] = useState({});
  const [response, setResponse] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [streamId, setStreamId] = useState("");
  const [republishInfo, setRepublishInfo] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [formData, setFormData] = useState({
    src_app: "",
    src_strm: "",
    dest_addr: "live-api-s.facebook.com",
    dest_port: "443",
    dest_app: "rtmp",
    dest_strm: "",
    description: "",
    tags: "",
    ssl: "",
  });
  const [server_id, setServer_id] = useState("");
  const [client_id, setClient_id] = useState("");
  const [api_key, setApi_key] = useState("");

  const fetchServerInfo = () => {
    const currentServer = localStorage.getItem("currentServer");
    if (currentServer) {
      const serverInfo = JSON.parse(currentServer);
      setServer_id(serverInfo.server_id);
      setClient_id(serverInfo.client_id);
      setApi_key(serverInfo.api_key);
    }
  };

  useEffect(() => {
    fetchServerInfo();
  }, []);

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const toggleModal2 = () => setIsModalOpen2(!isModalOpen2);
  const handleInputChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      src_app: formData.src_app,
      src_strm: formData.src_strm,
      dest_addr: formData.dest_addr,
      dest_port: formData.dest_port,
      dest_app: formData.dest_app,
      dest_strm: formData.dest_strm,
      description: formData.description,
      tags: formData.tags.split(",").map((tag) => tag.trim()),
      ssl: formData.ssl,
    };

    const url = `https://api.wmspanel.com/v1/server/${server_id}/rtmp/republish?client_id=${client_id}&api_key=${api_key}`;

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      toggleModal();
      setResponse(response);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  useEffect(() => {
    if (server_id && client_id && api_key) {
      axios
        .get(
          `https://api.wmspanel.com/v1/server/${server_id}/rtmp/republish?client_id=${client_id}&api_key=${api_key}`
        )
        .then((response) => {
          const data = response.rules;
          if (Array.isArray(data)) {
            setRepublishInfo(data);
          } else {
            setRepublishInfo([]);
          }
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, [server_id, client_id, api_key]);

  const handleDelete = async () => {
    const matchingInfo = getMatchingRepublishInfo(
      republishInfo,
      service.application,
      service.stream
    );
    if (matchingInfo) {
      try {
        await axios.delete(
          `https://api.wmspanel.com/v1/server/${server_id}/rtmp/republish/${matchingInfo}?client_id=${client_id}&api_key=${api_key}`
        );
        setIsModalOpen2(false);

        window.location.reload();
      } catch (error) {
        console.error("Error deleting republish info:", error);
      }
    }
  };

  const handleRestart = async () => {
    const matchingInfo = getMatchingRepublishInfo(
      republishInfo,
      service.application,
      service.stream
    );
    const url = `https://api.wmspanel.com/v1/server/${server_id}/rtmp/republish/${matchingInfo}/restart?client_id=${client_id}&api_key=${api_key}`;

    try {
      const response = await axios.get(url);
      if (response.status === "Ok") {
        console.warn("Restart successful");
      } else {
        console.error("Restart failed");
      }
    } catch (error) {
      console.error("Error during restart: ", error);
    }
  };

  useEffect(() => {
    if (server_id && client_id && api_key && id) {
      axios
        .get(
          `https://api.wmspanel.com/v1/server/${server_id}/live/streams?client_id=${client_id}&api_key=${api_key}`
        )
        .then((res) => {
          const foundStream = res.streams.find((stream) => stream.id === id);
          if (foundStream) {
            setService(foundStream);
          } else {
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoaded(true);
        });
    }
  }, [id, server_id, client_id, api_key]);

  useEffect(() => {
    if (streamId) {
      axios
        .get(
          `https://api.wmspanel.com/v1/server/${server_id}/rtmp/republish?client_id=${client_id}&api_key=${api_key}`
        )
        .then((res) => {
          const sortedStreams = res.streams.sort((a, b) =>
            a.stream.localeCompare(b.stream)
          );
          setService(sortedStreams[streamId]);
        })
        .catch((err) => {
          window.location.reload();
        });
    }
  }, [id, streamId, server_id, client_id, api_key]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      src_app: service.application || "",
      src_strm: service.stream || "",
      dest_addr: "live-api-s.facebook.com",
      dest_port: "443",
      dest_app: "rtmp",
    }));
  }, [service]);

  return (
    <>
      <div>
        <div className="text-start">
          <Button
            style={{ backgroundColor: "#4267B2" }}
            size="lg"
            onClick={toggleModal}
            className="m-2"
          >
            <i className=" fab fa-facebook m-1"></i>
            Republish
          </Button>

          <Button color="danger" onClick={() => setIsModalOpen2(true)}>
            <i className="fas fa-trash-alt m-2"></i>
            Delete
          </Button>
          <Button color="warning" className="m-2" onClick={handleRestart}>
            <i className="fas fa-redo m-2"></i>
            Restart
          </Button>
        </div>
        <Modal isOpen={isModalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Republish</ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="src_app">Source App</Label>
                <Input
                  type="text"
                  name="src_app"
                  id="src_app"
                  value={formData.src_app}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="src_strm">Source Stream</Label>
                <Input
                  type="text"
                  name="src_strm"
                  id="src_strm"
                  value={formData.src_strm}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="dest_addr">Destination Address</Label>
                <Input
                  type="text"
                  name="dest_addr"
                  id="dest_addr"
                  value={formData.dest_addr}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="dest_port">Destination Port</Label>
                <Input
                  type="text"
                  name="dest_port"
                  id="dest_port"
                  value={formData.dest_port}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="dest_app">Destination App</Label>
                <Input
                  type="text"
                  name="dest_app"
                  id="dest_app"
                  value={formData.dest_app}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="dest_strm">Destination Stream</Label>
                <Input
                  type="text"
                  name="dest_strm"
                  id="dest_strm"
                  value={formData.dest_strm}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="description">Description</Label>
                <Input
                  type="text"
                  name="description"
                  id="description"
                  value={formData.description}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="tags">Tags</Label>
                <Input
                  type="text"
                  name="tags"
                  id="tags"
                  value={formData.tags}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="ssl"
                    checked={formData.ssl}
                    onChange={(e) =>
                      setFormData({ ...formData, ssl: e.target.checked })
                    }
                  />
                  SSL
                </Label>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" onClick={handleSubmit}>
              Submit
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>

            {response && <pre>{JSON.stringify(response, null, 2)}</pre>}
            {error && <pre>{JSON.stringify(error, null, 2)}</pre>}
          </ModalFooter>
        </Modal>
        <Modal isOpen={isModalOpen2} toggle={toggleModal2}>
          <ModalHeader toggle={toggleModal2}>Republish Info</ModalHeader>
          <ModalBody>
            <p>Application: {service.application}</p>
            <p>Stream: {service.stream}</p>
            <p>Republish Info:</p>
            <p>
              ID:{" "}
              {republishInfo && service.application
                ? getMatchingRepublishInfo(
                    republishInfo,
                    service.application,
                    service.stream
                  )
                : "No matching republish info found."}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={handleDelete}>
              Delete
            </Button>
            <Button color="secondary" onClick={toggleModal2}>
              Close
            </Button>
            {error && <pre>{JSON.stringify(error, null, 2)}</pre>}
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

function getMatchingRepublishInfo(republishInfo, application, stream) {
  if (!Array.isArray(republishInfo)) {
    return "Invalid republish info data.";
  }

  const matchingRule = republishInfo.find(
    (rule) =>
      rule.dest_addr === "live-api-s.facebook.com" &&
      rule.src_app === application &&
      rule.src_strm === stream
  );

  if (matchingRule) {
    return matchingRule.id;
  } else {
    return "No matching republish info found.";
  }
}

export default Facebook;
