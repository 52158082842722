import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";

function Customer() {
  const [listOfCustomers, setListOfCustomers] = useState([]);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);

  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [selectedCustomerIndex, setSelectedCustomerIndex] = useState(0);
  const [selectedCustomerName, setSelectedCustomerName] = useState("");

  const toggle = () => {
    setModal(!modal);
  };

  const toggle1 = () => {
    setModal1(!modal1);
  };

  const toggle2 = () => {
    setModal2(!modal2);
  };

  const handleSelectedCustomer = (value, key) => {
    setSelectedCustomer(value);
    setSelectedCustomerIndex(key);
    setSelectedCustomerName(value.firstName + " " + value.lastName);
  };

  const handleCustomerName = (event) => {
    setSelectedCustomerName(event.target.value);
  };

  const handleUpdateCustomer = () => {
    const updatedCustomer = {
      id: selectedCustomer.id,
      firstName: selectedCustomerName,
      lastName: selectedCustomer.lastName,
      email: selectedCustomer.email,
      phone: selectedCustomer.phone,
      image: selectedCustomer.image,
      level: selectedCustomer.level,
      service: selectedCustomer.service,
      storage: selectedCustomer.storage,
    };
    axios
      .put(
        `http://195.87.127.148:3001/customer/${updatedCustomer.id}`,
        updatedCustomer
      )
      .then((response) => {
        if (response.status === 200) {
          const updatedCustomers = [...listOfCustomers];
          updatedCustomers[selectedCustomerIndex] = updatedCustomer;
          setListOfCustomers(updatedCustomers);
          setModal(false);
        } else {
          console.warn("Unexpected response: ", response);
        }
      })
      .catch((error) => {
        console.error("Error updating data: ", error);
      });
  };

  const handleDeleteCustomer = () => {
    axios
      .delete(`http://195.87.127.148:3001/customer/${selectedCustomer.id}`)
      .then((response) => {
        if (response.status === 200) {
          const updatedCustomers = [...listOfCustomers];
          updatedCustomers.splice(selectedCustomerIndex, 1);
          setListOfCustomers(updatedCustomers);
          setModal(false);
        } else {
          console.warn("Unexpected response: ", response);
        }
      })
      .catch((error) => {
        console.error("Error deleting data: ", error);
      });
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      axios
        .get("http://195.87.127.148:3001/auth/auth", {
          headers: {
            accessToken: accessToken,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const username = response.username;
          axios.get(`http://195.87.127.148:3001/customer`).then((response) => {
            const filteredCustomers = Array.isArray(response)
              ? response.filter((customer) => customer.createdBy === username)
              : [];
            setListOfCustomers(filteredCustomers);
          });
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
          setListOfCustomers([]);
        });
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Veriyum" breadcrumbItem="Customer" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="text-lg-end">
                    <Button
                      type="button"
                      color="success"
                      className="btn-primary"
                      href="/reseller-create-user"
                    >
                      Add Customer
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap table-hover mb-0">
                      <tbody>
                        {listOfCustomers.map((value, key) => (
                          <tr key={key}>
                            <td>
                              <div className="d-flex align-items-center">
                                <img
                                  src={value.image}
                                  className="avatar-xs rounded-circle me-2"
                                  alt=""
                                />
                                <h5 className="font-size-14 mb-1">
                                  <Link to={`/reseller-customer/${value.id}`}>
                                    {value.firstName} {value.lastName}
                                  </Link>
                                </h5>
                              </div>
                            </td>
                            <td>
                              <p className="text-muted mb-0">
                                <Button
                                  type="button"
                                  color={
                                    value.level === "reseller"
                                      ? "danger"
                                      : value.level === "User"
                                      ? "primary"
                                      : value.level === "Reseller"
                                      ? "warning"
                                      : "gray"
                                  }
                                  className="btn btn-primary btn-sm w-sm waves-effect waves-light"
                                >
                                  {value.level}
                                </Button>
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0">
                                {value.service} Media Services
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0">
                                {value.storage} GB
                              </p>
                            </td>
                            <td>
                              <ul className="list-inline font-size-20 contact-links mb-0">
                                <li className="list-inline-item px-2">
                                  <Link
                                    // edit function
                                    to="#editCustomer"
                                    onClick={() => {
                                      handleSelectedCustomer(value, key);
                                      toggle1();
                                    }}
                                  >
                                    <i className="bx bxs-edit"></i>
                                  </Link>
                                </li>
                                <li className="list-inline-item px-2">
                                  <Link
                                    // delete function
                                    onClick={() => {
                                      handleDeleteCustomer(value.id, key);
                                      toggle2();
                                    }}
                                  >
                                    <i className="bx bxs-trash"></i>
                                  </Link>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Customer</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <div>
                <div className="mb-3">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Customer Level</label>
                  <select className="form-select">
                    <option value="">Select Customer Level</option>
                    <option value="level1">User</option>
                    <option value="level2">Reseller</option>
                    <option value="level3">reseller</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label">Media Services</label>
                  <select className="form-select">
                    <option value="">Select Media Services</option>
                    <option value="service1">Service 1</option>
                    <option value="service2">Service 2</option>
                    <option value="service3">Service 3</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label">Storage</label>
                  <input type="text" className="form-control" />
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modal1} toggle={toggle1}>
        <ModalHeader toggle={toggle1}>Update Customer</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <div>
                <div className="mb-3">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedCustomerName}
                    onChange={handleCustomerName}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Customer Level</label>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedCustomer.level}
                    readOnly
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Media Services</label>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedCustomer.service}
                    readOnly
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Storage</label>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedCustomer.storage}
                    readOnly
                  />
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpdateCustomer}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={toggle1}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modal2} toggle={toggle2}>
        <ModalHeader toggle={toggle2}>Delete Customer</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <div>
                <p>Are you sure you want to delete this customer?</p>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle2}>
            Yes
          </Button>{" "}
          <Button color="secondary" onClick={toggle2}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default Customer;
