import React, { useState, useEffect } from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifi } from "@fortawesome/free-solid-svg-icons";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import FacebookLogo from "../../assets/images/facebook.png";
import TwitchLogo from "../../assets/images/twitch.png";
import TwitterLogo from "../../assets/images/twitter.png";
import YouTubeLogo from "../../assets/images/youtube.png";
import KickLogo from "../../assets/images/kick.png";
import CustomLogo from "../../assets/images/custom.png";
const UserDash = () => {
  document.title = "Dashboard | Veriyum - Admin & Dashboard";
  const { id } = useParams();
  const [listOfCustomers, setListOfCustomers] = useState({
    filteredData1: [],
    filteredData2: [],
  });
  const [server_id, setServer_id] = useState("");
  const [playerUrl, setPlayerUrl] = useState("");
  const [client_id, setClient_id] = useState("");
  const [salt, setSalt] = useState("");
  const [hash, setHash] = useState("");
  const [republishList, setRepublishList] = useState([]);
  const [republishDropdowns, setRepublishDropdowns] = useState({});
  const [checkStatus, setCheckStatus] = useState([]);
  const [appName, setAppName] = useState("");
  const [api_key, setApi_key] = useState("");
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [userId, setUserId] = useState("");

  const [authState, setAuthState] = useState({
    id: "",
    userId: "",
    role: "",
  });

  useEffect(() => {
    axios
      .get("http://195.87.127.148:3001/auth/auth", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.error) {
          setAuthState((prevState) => ({ ...prevState, status: false }));
        } else {
          setAuthState({
            id: response.id,
            userId: response.id,
            role: response.role,
            username: response.username,
          });
          setUserId(response.id);
          setRole(response.role);
          setUsername(response.username);
        }
      })
      .catch((error) => {
        console.error("Auth error:", error);
      });
  }, []);

  const toggleRepublishDropdown = (streamId) => {
    setRepublishDropdowns((prevState) => ({
      ...prevState,
      [streamId]: !prevState[streamId],
    }));
  };

  const fetchRepublishList = () => {
    axios
      .get("http://195.87.127.148:3001/republish")
      .then((response) => {
        const data = response;

        if (data) {
          setRepublishList(data);
        } else {
          setRepublishList([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching republish data from local API:", error);
      });
  };

  useEffect(() => {
    fetchRepublishList();
  }, [server_id, client_id, api_key]);

  const handleSwitchChange = (ruleId, isActive) => {
    const shouldChange = window.confirm("Are you sure?");
    if (!shouldChange) {
      return;
    }

    let updatedRule;

    axios
      .get(
        `https://api.wmspanel.com/v1/server/${server_id}/rtmp/republish/${ruleId}?client_id=${client_id}&api_key=${api_key}`
      )
      .then((response) => {
        const existingRule = response.rule;
        updatedRule = { ...existingRule, paused: !isActive };

        return axios.put(
          `https://api.wmspanel.com/v1/server/${server_id}/rtmp/republish/${ruleId}?client_id=${client_id}&api_key=${api_key}`,
          updatedRule,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      })
      .then(() => {
        alert(`${isActive ? "Resumed" : "Paused"}...`);

        setRepublishList((prevList) =>
          prevList.map((rule) =>
            rule.id === ruleId ? { ...rule, paused: updatedRule.paused } : rule
          )
        );
      })
      .catch((error) => {
        console.error("Error updating WMSPanel API:", error);
        alert("An error occurred while updating the republish rule.");
      });
  };

  const fetchServerInfo = () => {
    const currentServer = localStorage.getItem("currentServer");
    if (currentServer) {
      const serverInfo = JSON.parse(currentServer);
      setServer_id(serverInfo.server_id);
      setClient_id(serverInfo.client_id);
      setApi_key(serverInfo.api_key);
      setSalt(serverInfo.salt);
      setHash(serverInfo.hash);
    }
  };

  useEffect(() => {
    if (server_id && client_id && api_key) {
      const storedData = localStorage.getItem("playerUrl");

      if (storedData) {
        try {
          const trimmedData = storedData.trim();
          const parsedData = JSON.parse(trimmedData);
          if (parsedData.server_id === server_id) {
            setPlayerUrl(parsedData.playerUrl);
            return;
          }
        } catch (error) {
          console.error("Error parsing stored data:", error);
        }
      }

      axios
        .get(
          `https://api.wmspanel.com/v1/server/${server_id}?client_id=${client_id}&api_key=${api_key}`
        )
        .then((response) => {
          const data = response;
          if (data && data.server && data.server.ip && data.server.ip[0]) {
            const playerUrl = data.server.ip[0];
            setPlayerUrl(playerUrl);
            localStorage.setItem(
              "playerUrl",
              JSON.stringify({ server_id, playerUrl })
            );
          } else {
            setPlayerUrl();
          }
        })
        .catch((error) => {
          console.error("Error fetching player URL:", error);
        });
    }
  }, [server_id, client_id, api_key]);

  useEffect(() => {
    fetchServerInfo();
  }, []);

  const fetchData = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        throw new Error("User ID not found in localStorage.");
      }

      // Verileri streams API'sinden al
      const streamsResponse = await axios.get(
        "http://195.87.127.148:3001/streams",
        {
          params: { userId },
          headers: { "Content-Type": "application/json" },
        }
      );

      // Filtre verilerini al
      const filterStream = JSON.parse(localStorage.getItem("currentServer"));
      if (!filterStream) {
        throw new Error("Current server not found in localStorage.");
      }

      const filters = filterStream.filter
        .split(",")
        .map((filter) => filter.trim());

      setAppName(filters);

      // Filtre verilerini kullanarak verileri filtrele

      if (!filters || filters.length === 0) {
        throw new Error("Filters not found or empty in current server data.");
      }

      // Verileri filtrele
      const filteredData = streamsResponse.filter((stream) => {
        return filters.some((filter) =>
          stream.application.toLowerCase().includes(filter.toLowerCase())
        );
      });

      // Filtrelenmiş verileri filtrelere göre ayır
      const filteredDataByFilter = {};
      filters.forEach((filter) => {
        filteredDataByFilter[filter] = filteredData.filter((stream) =>
          stream.application.toLowerCase().includes(filter.toLowerCase())
        );
      });

      // Filtrelenmiş verileri ikiye böl
      const filteredData1 = [];
      const filteredData2 = [];
      Object.values(filteredDataByFilter).forEach((filterData, index) => {
        if (index % 2 === 0) {
          filteredData1.push(...filterData);
        } else {
          filteredData2.push(...filterData);
        }
      });

      setListOfCustomers({
        filteredData1,
        filteredData2,
      });

      if (filteredData.length === 0) {
      }
    } catch (err) {
      console.error("Error fetching data: ", err);
    } finally {
    }
  };

  useEffect(() => {
    const nimbleStatus = async () => {
      try {
        const response = await axios.get(
          `http://${playerUrl}:8082/manage/live_streams_status?salt=${salt}&hash=${hash}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setCheckStatus(response || []);
      } catch (error) {
        setCheckStatus([]);
      }
    };

    const fetchNimbleStatus = async () => {
      await nimbleStatus();
      await fetchData();
    };
    fetchNimbleStatus();
    const intervalId = setInterval(fetchNimbleStatus, 10000);

    return () => clearInterval(intervalId);
  }, [userId, playerUrl, salt, hash]);

  const fetchAndSendData = async () => {
    try {
      const response = await axios.get(
        `https://api.wmspanel.com/v1/server/${server_id}/live/streams?client_id=${client_id}&api_key=${api_key}`
      );

      if (Array.isArray(response.streams)) {
        const sortedStreams = response.streams.sort((a, b) =>
          a.stream.localeCompare(b.stream)
        );
        await axios.post("http://195.87.127.148:3001/streams/", {
          streams: sortedStreams,
          userId,
        });
      } else {
        console.warn("Unexpected response: ", response.streams);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  return (
    <React.Fragment>
      <div className="page-item">
        <Container fluid={true}>
          <Row className="page-content">
            <Col lg={12}>
              <Card>
                <CardHeader className="h4 bg-transparent border-bottom text-uppercase text-center">
                  <Button
                    color="success"
                    className="float-start"
                    onClick={fetchAndSendData}
                    id="create-btn"
                    disabled={role === "reseller"}
                  >
                    Refresh Stream List
                  </Button>
                  Welcome {username} !
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg={6}>
                      <Card>
                        <CardBody>
                          <div id="customerList">
                            <div className="table-responsive table-card mt-3 mb-1">
                              <table
                                className="table align-middle table-nowrap"
                                id="customerTable"
                              >
                                <thead className="table-light">
                                  <tr>
                                    <th
                                      className="sort text-capitalize font-size-14"
                                      data-sort="application"
                                    >
                                      {appName[0]}
                                    </th>
                                    <th
                                      className="sort text-capitalize font-size-14"
                                      data-sort="application"
                                    >
                                      Republish
                                    </th>
                                    <th
                                      className="sort text-end font-size-14"
                                      data-sort="protocol"
                                    >
                                      Protocol
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="list">
                                  {listOfCustomers.filteredData1
                                    .sort((a, b) =>
                                      a.stream.localeCompare(b.stream)
                                    )
                                    .map((service, key) => {
                                      let isOnline;
                                      try {
                                        const matchingApp = checkStatus.find(
                                          (stream) =>
                                            stream.app === service.application
                                        );
                                        const matchingStream = matchingApp
                                          ? matchingApp.streams.find(
                                              (s) => s.strm === service.stream
                                            )
                                          : null;
                                        isOnline =
                                          !!matchingStream ||
                                          service.status === "online";
                                      } catch (error) {
                                        isOnline = service.status === "online";
                                      }

                                      return (
                                        <tr key={"_service_" + key}>
                                          <td className="type">
                                            <h5 className="text-truncate text-capitalize font-size-20 mb-1">
                                              <Link
                                                to={
                                                  role === "admin"
                                                    ? `/admin-dashboard/${service.id}`
                                                    : `/dashboard/${service.id}`
                                                }
                                                className="text-success"
                                              >
                                                {isOnline ? (
                                                  <div className="text-capitilaze">
                                                    <FontAwesomeIcon
                                                      icon={faWifi}
                                                      style={{ color: "green" }}
                                                      beatFade
                                                    />{" "}
                                                    {service.stream}
                                                  </div>
                                                ) : (
                                                  <div className="text-capitilaze">
                                                    <FontAwesomeIcon
                                                      icon={faWifi}
                                                      style={{ color: "red" }}
                                                      beatFade
                                                    />{" "}
                                                    {service.stream}
                                                  </div>
                                                )}
                                              </Link>
                                            </h5>
                                          </td>
                                          <td style={{ width: "200px" }}>
                                            <Dropdown
                                              direction="up"
                                              isOpen={
                                                republishDropdowns[service.id]
                                              }
                                              toggle={() =>
                                                toggleRepublishDropdown(
                                                  service.id
                                                )
                                              }
                                            >
                                              <DropdownToggle>
                                                Republish
                                              </DropdownToggle>
                                              <DropdownMenu>
                                                {republishList
                                                  .filter(
                                                    (republish) =>
                                                      republish.src_app ===
                                                        service.application &&
                                                      republish.src_strm ===
                                                        service.stream
                                                  )
                                                  .map((republish, index) => (
                                                    <DropdownItem key={index}>
                                                      {republish.dest_addr ===
                                                      "fa723fc1b171.global-contribute.live-video.net" ? (
                                                        <>
                                                          <img
                                                            src={KickLogo}
                                                            alt="Kick"
                                                            height={27}
                                                            width={81}
                                                          />{" "}
                                                        </>
                                                      ) : republish.dest_addr ===
                                                        "a.rtmp.youtube.com" ? (
                                                        <>
                                                          <img
                                                            src={YouTubeLogo}
                                                            alt="YouTube"
                                                            height={27}
                                                            width={81}
                                                          />{" "}
                                                        </>
                                                      ) : republish.dest_addr ===
                                                        "live-api-s.facebook.com" ? (
                                                        <>
                                                          <img
                                                            src={FacebookLogo}
                                                            alt="Facebook"
                                                            height={27}
                                                            width={81}
                                                          />{" "}
                                                        </>
                                                      ) : republish.dest_addr ===
                                                        "muc01.contribute.live-video.net" ? (
                                                        <>
                                                          <img
                                                            src={TwitchLogo}
                                                            alt="Twitch"
                                                            height={27}
                                                            width={81}
                                                          />{" "}
                                                        </>
                                                      ) : republish.dest_addr ===
                                                        "de.pscp.tv" ? (
                                                        <>
                                                          <img
                                                            src={TwitterLogo}
                                                            alt="Twitter"
                                                            height={27}
                                                            width={81}
                                                          />{" "}
                                                        </>
                                                      ) : (
                                                        <>
                                                          <img
                                                            src={CustomLogo}
                                                            alt="Custom"
                                                            height={27}
                                                            width={81}
                                                          />{" "}
                                                        </>
                                                      )}
                                                      <BootstrapSwitchButton
                                                        key={index} // Her bir eleman için benzersiz bir anahtar sağla
                                                        checked={
                                                          !republish.paused
                                                        }
                                                        onChange={(checked) =>
                                                          handleSwitchChange(
                                                            republish.id,
                                                            checked
                                                          )
                                                        }
                                                        size="sm"
                                                        onstyle="warning"
                                                        offstyle="secondary"
                                                      />
                                                    </DropdownItem>
                                                  ))}
                                              </DropdownMenu>
                                            </Dropdown>
                                          </td>
                                          <td className=" text-end">
                                            <h5 className="text-truncate text-capitalize font-size-20 mb-1">
                                              <Link
                                                to={
                                                  role === "reseller"
                                                    ? `/reseller-dashboard/${service.id}`
                                                    : `/admin-dashboard/${service.id}`
                                                }
                                              >
                                                {service.protocol}
                                              </Link>
                                            </h5>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={6}>
                      <Card>
                        <CardBody>
                          <div id="customerList">
                            <div className="table-responsive table-card mt-3 mb-1">
                              <table
                                className="table align-middle table-nowrap"
                                id="customerTable"
                              >
                                <thead className="table-light">
                                  <tr>
                                    <th
                                      className="sort text-capitalize font-size-14"
                                      data-sort="application"
                                    >
                                      {appName[1]}
                                    </th>
                                    <th
                                      className="sort text-capitalize font-size-14"
                                      data-sort="application"
                                    >
                                      Republish
                                    </th>
                                    <th
                                      className="sort text-end font-size-14"
                                      data-sort="protocol"
                                    >
                                      Protocol
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="list">
                                  {listOfCustomers.filteredData2
                                    .sort((a, b) =>
                                      a.stream.localeCompare(b.stream)
                                    )
                                    .map((service, key) => {
                                      let isOnline;
                                      try {
                                        const matchingApp = checkStatus.find(
                                          (stream) =>
                                            stream.app === service.application
                                        );
                                        const matchingStream = matchingApp
                                          ? matchingApp.streams.find(
                                              (s) => s.strm === service.stream
                                            )
                                          : null;
                                        isOnline =
                                          !!matchingStream ||
                                          service.status === "online";
                                      } catch (error) {
                                        isOnline = service.status === "online";
                                      }

                                      return (
                                        <tr key={"_service_" + key}>
                                          <td className="type">
                                            <h5 className="text-truncate text-capitalize font-size-20 mb-1">
                                              <Link
                                                to={
                                                  role === "reseller"
                                                    ? `/reseller-dashboard/${service.id}`
                                                    : `/admin-dashboard/${service.id}`
                                                }
                                                className="text-success"
                                              >
                                                {isOnline ? (
                                                  <div className="text-capitilaze">
                                                    <FontAwesomeIcon
                                                      icon={faWifi}
                                                      style={{ color: "green" }}
                                                      beatFade
                                                    />{" "}
                                                    {service.stream}
                                                  </div>
                                                ) : (
                                                  <div className="text-capitilaze">
                                                    <FontAwesomeIcon
                                                      icon={faWifi}
                                                      style={{ color: "red" }}
                                                      beatFade
                                                    />{" "}
                                                    {service.stream}
                                                  </div>
                                                )}
                                              </Link>
                                            </h5>
                                          </td>
                                          <td style={{ width: "200px" }}>
                                            <Dropdown
                                              direction="up"
                                              isOpen={
                                                republishDropdowns[service.id]
                                              }
                                              toggle={() =>
                                                toggleRepublishDropdown(
                                                  service.id
                                                )
                                              }
                                            >
                                              <DropdownToggle caret>
                                                Republish
                                              </DropdownToggle>
                                              <DropdownMenu>
                                                {republishList
                                                  .filter(
                                                    (republish) =>
                                                      republish.src_app ===
                                                        service.application &&
                                                      republish.src_strm ===
                                                        service.stream
                                                  )
                                                  .map((republish, index) => (
                                                    <DropdownItem key={index}>
                                                      {republish.dest_addr ===
                                                      "fa723fc1b171.global-contribute.live-video.net" ? (
                                                        <>
                                                          <img
                                                            src={KickLogo}
                                                            alt="Kick"
                                                            height={27}
                                                            width={81}
                                                          />{" "}
                                                        </>
                                                      ) : republish.dest_addr ===
                                                        "a.rtmp.youtube.com" ? (
                                                        <>
                                                          <img
                                                            src={YouTubeLogo}
                                                            alt="YouTube"
                                                            height={27}
                                                            width={81}
                                                          />{" "}
                                                        </>
                                                      ) : republish.dest_addr ===
                                                        "live-api-s.facebook.com" ? (
                                                        <>
                                                          <img
                                                            src={FacebookLogo}
                                                            alt="Facebook"
                                                            height={27}
                                                            width={81}
                                                          />{" "}
                                                        </>
                                                      ) : republish.dest_addr ===
                                                        "muc01.contribute.live-video.net" ? (
                                                        <>
                                                          <img
                                                            src={TwitchLogo}
                                                            alt="Twitch"
                                                            height={27}
                                                            width={81}
                                                          />{" "}
                                                        </>
                                                      ) : republish.dest_addr ===
                                                        "de.pscp.tv" ? (
                                                        <>
                                                          <img
                                                            src={TwitterLogo}
                                                            alt="Twitter"
                                                            height={27}
                                                            width={81}
                                                          />{" "}
                                                        </>
                                                      ) : (
                                                        <>
                                                          <img
                                                            src={CustomLogo}
                                                            alt="Custom"
                                                            height={27}
                                                            width={81}
                                                          />{" "}
                                                        </>
                                                      )}
                                                      <BootstrapSwitchButton
                                                        key={index} // Her bir eleman için benzersiz bir anahtar sağla
                                                        checked={
                                                          !republish.paused
                                                        }
                                                        onChange={(checked) =>
                                                          handleSwitchChange(
                                                            republish.id,
                                                            checked
                                                          )
                                                        }
                                                        size="sm"
                                                        onstyle="warning"
                                                        offstyle="secondary"
                                                      />
                                                    </DropdownItem>
                                                  ))}
                                              </DropdownMenu>
                                            </Dropdown>
                                          </td>
                                          <td className="phone text-end">
                                            <h5 className="text-truncate text-capitalize font-size-20 mb-1">
                                              <Link
                                                to={
                                                  role === "reseller"
                                                    ? `/reseller-dashboard/${service.id}`
                                                    : `/admin-dashboard/${service.id}`
                                                }
                                              >
                                                {service.protocol}
                                              </Link>
                                            </h5>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserDash;
