import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Table,
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import SLDPPlayer from "./sldpplayer";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

import FacebookLogo from "../../assets/images/facebook.png";
import TwitchLogo from "../../assets/images/twitch.png";
import TwitterLogo from "../../assets/images/twitter.png";
import YouTubeLogo from "../../assets/images/youtube.png";
import KickLogo from "../../assets/images/kick.png";
import CustomLogo from "../../assets/images/custom.png";

function Overview() {
  document.title = "Dashboard Details | Veriyum - Admin & Dashboard";
  const { id } = useParams();
  const location = useLocation();
  const playerRef = useRef(null);
  const [service, setService] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [setError] = useState(null);
  const [server_id, setServer_id] = useState("");
  const [client_id, setClient_id] = useState("");
  const [api_key, setApi_key] = useState("");
  const [modal, setModal] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [streamKey, setStreamKey] = useState("");
  const [republishList, setRepublishList] = useState([]);
  const [playerKey, setPlayerKey] = useState(0);
  const [playerUrl, setPlayerUrl] = useState("");
  const [userId, setUserId] = useState("");
  const [authState, setAuthState] = useState({
    id: "",
    userId: "",
  });
  const [republishData, setRepublishData] = useState({
    src_app: "",
    src_strm: "",
    dest_addr: "",
    dest_port: "",
    dest_app: "",
    dest_strm: "",
    description: "",
    tags: "",
    ssl: false,
  });
  const [, setRuleId] = useState(null); // Track rule ID

  useEffect(() => {
    // Auth state fetch
    axios
      .get("http://195.87.127.148:3001/auth/auth", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.error) {
          setAuthState({ ...authState, status: false });
        } else {
          setAuthState({
            id: response.id,
            userId: response.id,
          });
          setUserId(response.id);
        }
      });
  }, []);

  useEffect(() => {
    setPlayerKey((prevKey) => prevKey + 1);

    return () => {
      const currentPlayerRef = playerRef.current;
      if (currentPlayerRef && currentPlayerRef.destroy) {
        currentPlayerRef.destroy();
      }
    };
  }, [location]);

  const handleDelete = (id) => {
    const shouldDelete = window.confirm("Are you sure?");
    if (!shouldDelete) {
      return;
    }

    // Delete from api.wmspanel.com
    axios
      .delete(
        `https://api.wmspanel.com/v1/server/${server_id}/rtmp/republish/${id}?client_id=${client_id}&api_key=${api_key}`
      )
      .then((response) => {
        // Delete from local republish API
        axios
          .delete(`http://195.87.127.148:3001/republish/${id}`) // Assuming you have a delete endpoint for local API
          .then((response) => {
            // Update the local republish list
            fetchRepublishDataFromLocal();
          })
          .catch((error) => {
            console.error("Error deleting rule from local API:", error);
          });
      })
      .catch((error) => {
        console.error("Error deleting rule from WMSPanel:", error);
      });
  };

  useEffect(() => {
    const storedData = localStorage.getItem("playerUrl");

    if (storedData) {
      try {
        const trimmedData = storedData.trim();
        const parsedData = JSON.parse(trimmedData);
        if (parsedData.server_id === server_id) {
          setPlayerUrl(parsedData.playerUrl);
        } else {
          setPlayerUrl();
        }
      } catch (error) {
        console.error("Error parsing stored data:", error);
        setPlayerUrl();
      }
    }
  }, [server_id]);

  // Function to fetch republish data from the local API
  const fetchRepublishDataFromLocal = () => {
    axios
      .get("http://195.87.127.148:3001/republish")
      .then((response) => {
        setRepublishList(response); // Assuming the API returns an array of republish data
      })
      .catch((error) => {
        console.error("Error fetching republish data from local API:", error);
      });
  };

  useEffect(() => {
    fetchRepublishDataFromLocal();
  }, []);

  useEffect(() => {
    if (server_id && client_id && api_key) {
      fetchRepublishList();
    }
  }, [server_id, client_id, api_key]);

  const fetchServerInfo = () => {
    const currentServer = localStorage.getItem("currentServer");
    if (currentServer) {
      const serverInfo = JSON.parse(currentServer);
      setServer_id(serverInfo.server_id);
      setClient_id(serverInfo.client_id);
      setApi_key(serverInfo.api_key);
    }
  };

  useEffect(() => {
    fetchServerInfo();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (server_id && client_id && api_key) {
      axios
        .get(`http://195.87.127.148:3001/streams/${id}`)
        .then((res) => {
          setService(res);
          setRepublishData((prevState) => ({
            ...prevState,
            src_app: res.application,
            src_strm: res.stream,
          }));
        })
        .catch((error) => {
          console.error(error);
          setError(error.message);
        })
        .finally(() => {
          setIsLoaded(true);
        });
    }
  }, [id, navigate, server_id, client_id, api_key]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setRepublishData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const toggleModal = () => setModal(!modal);

  const handleAddRepublish = () => {
    // Access the latest republishData state here
    const { dest_addr, dest_port, dest_app, description, tags, ssl } =
      republishData;

    // Prepare data for API request
    const republishDataToSend = {
      src_app: service.application,
      src_strm: service.stream,
      dest_addr,
      dest_port,
      dest_app,
      dest_strm: streamKey,
      description,
      tags: tags.split(",").map((tag) => tag.trim()), // Handle tags
      ssl: !!ssl, // Ensure ssl is a boolean
    };

    // Only send the request if service is available
    if (service.application && service.stream) {
      sendRepublishRequest(republishDataToSend);
    }
  };

  const sendRepublishRequest = (data) => {
    // Send to api.wmspanel.com
    axios
      .post(
        `https://api.wmspanel.com/v1/server/${server_id}/rtmp/republish?client_id=${client_id}&api_key=${api_key}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // After successful api.wmspanel.com response, send to local republish API
        sendRepublishToLocal(data, res.rule.id, data.dest_strm); // Pass dest_strm
        toggleModal();
      })
      .catch((error) => {
        console.error("API Error:", error);
        console.error("Error Details:", error.response); // Inspect the error response
      });
  };

  // Function to send data to the local republish API
  const sendRepublishToLocal = (data, ruleId, destStrm) => {
    axios
      .post(
        "http://195.87.127.148:3001/republish",
        {
          ...data,
          id: ruleId,
          userId: userId,
          dest_strm: destStrm,
          custom_name: "",
          description: data.description,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        fetchRepublishDataFromLocal();
      })
      .catch((error) => {
        console.error("Error sending to local republish API:", error);
      });
  };

  const populateFormFields = (platform) => {
    switch (platform) {
      case "Facebook":
        setRepublishData({
          ...republishData,
          dest_addr: "live-api-s.facebook.com",
          dest_port: "443",
          dest_app: "rtmp",
        });
        break;
      case "Twitch":
        setRepublishData({
          ...republishData,
          dest_addr: "muc01.contribute.live-video.net",
          dest_port: "",
          dest_app: "app",
        });
        break;
      case "Twitter":
        setRepublishData({
          ...republishData,
          dest_addr: "de.pscp.tv",
          dest_port: "80",
          dest_app: "x",
        });
        break;
      case "YouTube":
        setRepublishData({
          ...republishData,
          dest_addr: "a.rtmp.youtube.com",
          dest_port: "1935",
          dest_app: "live2",
        });
        break;
      case "Kick":
        setRepublishData({
          ...republishData,
          dest_addr: "fa723fc1b171.global-contribute.live-video.net",
          dest_port: "443",
          dest_app: "app",
        });
        break;
      case "Custom":
        setRepublishData({
          ...republishData,
        });
        break;
      default:
        break;
    }
  };

  const handleSwitchChange = (ruleId, isActive) => {
    const shouldChange = window.confirm("Are you sure?");
    if (!shouldChange) {
      return;
    }

    setRuleId(ruleId);

    let updatedRule;

    axios
      .get(
        `https://api.wmspanel.com/v1/server/${server_id}/rtmp/republish/${ruleId}?client_id=${client_id}&api_key=${api_key}`
      )
      .then((response) => {
        const existingRule = response.rule;
        updatedRule = { ...existingRule, paused: !isActive };

        // api.wmspanel.com'a güncellenmiş kuralı gönder
        return axios.put(
          `https://api.wmspanel.com/v1/server/${server_id}/rtmp/republish/${ruleId}?client_id=${client_id}&api_key=${api_key}`,
          updatedRule,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      })
      .then((response) => {
        // Kullanıcıya bildirim göster
        alert(`${isActive ? "Resumed" : "Paused"}...`);

        // Yerel listeyi güncelle (state'i güncelle)
        setRepublishList((prevList) =>
          prevList.map(
            (rule) =>
              rule.id === ruleId
                ? { ...rule, paused: updatedRule.paused }
                : rule // paused değerini updatedRule'dan al
          )
        );
      })
      .catch((error) => {
        console.error("Error updating WMSPanel API:", error);
        alert("Republish kuralı güncellenirken bir hata oluştu.");
      });
  };

  const fetchRepublishList = () => {
    if (server_id && client_id && api_key) {
      axios
        .get(
          `https://api.wmspanel.com/v1/server/${server_id}/rtmp/republish?client_id=${client_id}&api_key=${api_key}`
        )
        .then((response) => {
          const data = response.rules;
          if (data) {
            setRepublishList(data);
          } else {
            setRepublishList([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching republish list:", error);
        });
    }
  };

  useEffect(() => {
    fetchRepublishList();
  }, [server_id, client_id, api_key]);

  return (
    <React.Fragment>
      <Row className="page-content">
        <div className="col-sm-6 col-xl-6 mb-3 ">
          <Card>
            <CardBody>
              <CardTitle className="h3 p-3 text-center text-uppercase">
                {service.application} / {service.stream}
              </CardTitle>

              <div className="table-responsive">
                <Table responsive className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <td>Stream ID</td>
                      <td>{service.id}</td>
                    </tr>
                    <tr>
                      <td>Application</td>
                      <td>{service.application}</td>
                    </tr>
                    <tr>
                      <td>Audio Codec</td>
                      <td>{service.audio_codec}</td>
                    </tr>
                    <tr>
                      <td>Stream Description</td>
                      <td>{service.description}</td>
                    </tr>
                    <tr>
                      <td>bandwidth</td>
                      <td>{service.bandwidth}</td>
                    </tr>
                    <tr>
                      <td>Stream Protocol</td>
                      <td>{service.protocol}</td>
                    </tr>
                    <tr>
                      <td>Stream Publish Time</td>
                      <td>
                        {service.publish_time && !isNaN(service.publish_time)
                          ? new Date(service.publish_time * 1000)
                              .toISOString()
                              .substr(11, 8)
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td>Stream Status</td>
                      <td>{service.status}</td>
                    </tr>
                    <tr>
                      <td>Stream</td>
                      <td>{service.stream}</td>
                    </tr>
                    <tr>
                      <td>Video Codec</td>
                      <td>{service.video_codec}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-sm-6 col-xl-6">
          <Card className="align-items-center">
            <div className="text-center mt-2">
              {service.status === "online" ? (
                <i className="fa fa-circle text-success">
                  <span className="ml-3"> </span>{" "}
                </i>
              ) : (
                <i className="fa fa-circle text-danger"></i>
              )}{" "}
              <span className="ml-2">Stream Status:</span>
              <span className="ml-3 text-white text-capitalize">
                {" "}
                {service.status}
              </span>
            </div>
            <div className="player-wrapper d-flex justify-content-center">
              {isLoaded && (
                <div className="embed-responsive embed-responsive-16by9">
                  <SLDPPlayer
                    key={playerKey}
                    streamUrl={`${playerUrl}:8081/${service.application}/${service.stream}`}
                    autoPlay
                    height={window.innerWidth <= 768 ? "auto" : 450} // Mobil cihazlarda yüksekliği otomatik ayarla
                    width={window.innerWidth <= 768 ? "auto" : 640} // Mobil cihazlarda genişliği otomatik ayarla
                  />
                </div>
              )}
            </div>
          </Card>
        </div>
        <Row>
          <div className="col-sm-12 col-xl-12 mb-3">
            <Card>
              <CardBody>
                <CardTitle className="h3 p-3 text-center text-uppercase">
                  | Republish |
                </CardTitle>
                <Button color="primary" onClick={toggleModal}>
                  Republish
                </Button>
                <Row>
                  <div className="col-sm-12 col-xl-12 mb-3">
                    <Card>
                      <CardBody>
                        <CardTitle className="h3 p-3 text-center text-uppercase">
                          | Republish List |
                        </CardTitle>
                        <div className="table-responsive">
                          <Table responsive className="table-nowrap mb-0">
                            <thead>
                              <tr>
                                <th className="text-center">Stream Name/Id</th>
                                <th> Stream Url</th>
                                <th>Active</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(republishList || []).filter(
                                (republish) =>
                                  republish.src_app === service.application &&
                                  republish.src_strm === service.stream
                              ).length > 0 ? (
                                (republishList || [])
                                  .filter(
                                    (republish) =>
                                      republish.src_app ===
                                        service.application &&
                                      republish.src_strm === service.stream
                                  )
                                  .map((republish) => {
                                    let platformLogo = null;
                                    if (
                                      republish.dest_addr.includes(
                                        "live-api-s.facebook.com"
                                      )
                                    ) {
                                      platformLogo = (
                                        <img
                                          src={FacebookLogo}
                                          alt="Facebook"
                                          width="75"
                                        />
                                      );
                                    } else if (
                                      republish.dest_addr.includes(
                                        "muc01.contribute.live-video.net"
                                      )
                                    ) {
                                      platformLogo = (
                                        <img
                                          src={TwitchLogo}
                                          alt="Twitch"
                                          width="75"
                                        />
                                      );
                                    } else if (
                                      republish.dest_addr.includes("de.pscp.tv")
                                    ) {
                                      platformLogo = (
                                        <img
                                          src={TwitterLogo}
                                          alt="Twitter"
                                          width="75"
                                        />
                                      );
                                    } else if (
                                      republish.dest_addr.includes(
                                        "a.rtmp.youtube.com"
                                      )
                                    ) {
                                      platformLogo = (
                                        <img
                                          src={YouTubeLogo}
                                          alt="YouTube"
                                          width="75"
                                        />
                                      );
                                    } else if (
                                      republish.dest_addr.includes(
                                        "fa723fc1b171.global-contribute.live-video.net"
                                      )
                                    ) {
                                      platformLogo = (
                                        <img
                                          src={KickLogo}
                                          alt="Kick"
                                          width="75"
                                        />
                                      );
                                    } else {
                                      platformLogo = (
                                        <img
                                          src={CustomLogo}
                                          alt="Custom"
                                          width="75"
                                        />
                                      );
                                    }

                                    return (
                                      <tr key={republish.id}>
                                        <td>
                                          {platformLogo}{" "}
                                          {republish.description
                                            ? republish.description
                                            : republish.id}
                                        </td>
                                        <td
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            const textToCopy = `${
                                              republish.dest_addr.includes(
                                                "live-api-s.facebook.com"
                                              )
                                                ? "rtmps"
                                                : "rtmp"
                                            }://${republish.dest_addr}:${
                                              republish.dest_port
                                            }/${republish.dest_app}/${
                                              republish.dest_strm
                                            }`;

                                            // Panoya kopyalama işlemi
                                            if (
                                              navigator.clipboard &&
                                              navigator.clipboard.writeText
                                            ) {
                                              // Modern tarayıcılar için
                                              navigator.clipboard
                                                .writeText(textToCopy)
                                                .then(() => {
                                                  alert(
                                                    "Yayın adresi panoya kopyalandı!"
                                                  ); // Başarılı olursa bildirim göster
                                                })
                                                .catch((err) => {
                                                  console.error(
                                                    "Panoya kopyalanamadı:",
                                                    err
                                                  );
                                                });
                                            } else {
                                              // Eski tarayıcılar veya izin verilmemişse
                                              const tempInput =
                                                document.createElement(
                                                  "textarea"
                                                );
                                              tempInput.value = textToCopy;
                                              document.body.appendChild(
                                                tempInput
                                              );
                                              tempInput.select();
                                              document.execCommand("copy");
                                              document.body.removeChild(
                                                tempInput
                                              );
                                              alert(
                                                "Yayın adresi panoya kopyalandı!"
                                              );
                                            }
                                          }}
                                        >
                                          {`${
                                            republish.dest_addr.includes(
                                              "live-api-s.facebook.com"
                                            )
                                              ? "rtmps"
                                              : "rtmp"
                                          }://${republish.dest_addr}:${
                                            republish.dest_port
                                          }/${republish.dest_app}/${
                                            republish.dest_strm
                                          }`}
                                        </td>
                                        <td
                                          className="text-center"
                                          style={{ width: "100px" }}
                                        >
                                          <BootstrapSwitchButton
                                            checked={!republish.paused}
                                            onlabel="On"
                                            offlabel="Off"
                                            onstyle="warning"
                                            offstyle="secondary"
                                            onChange={(checked) =>
                                              handleSwitchChange(
                                                republish.id,
                                                checked
                                              )
                                            }
                                          />
                                        </td>
                                        {/* Delete butonu */}
                                        <td className="text-center">
                                          <Button
                                            color="danger"
                                            onClick={() =>
                                              handleDelete(republish.id)
                                            }
                                          >
                                            <i className="fa fa-trash"></i>
                                          </Button>
                                        </td>
                                      </tr>
                                    );
                                  })
                              ) : (
                                <tr>
                                  <td colSpan="9">
                                    No republish rules found for application{" "}
                                    {service.application
                                      ? service.application.toUpperCase()
                                      : ""}{" "}
                                    /{" "}
                                    {service.stream
                                      ? service.stream.toUpperCase()
                                      : ""}{" "}
                                    .
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Row>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Republish Rule</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="platformSelect">Select Platform</Label>
              <Input
                type="select"
                id="platformSelect"
                value={selectedPlatform}
                onChange={(e) => {
                  setSelectedPlatform(e.target.value);
                  populateFormFields(e.target.value);
                }}
              >
                <option value="">Select</option>
                <option value="Facebook">Facebook</option>
                <option value="Twitch">Twitch</option>
                <option value="Twitter">Twitter</option>
                <option value="YouTube">YouTube</option>
                <option value="Kick">Kick</option>
                <option value="Custom">Custom</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="src_app">Source Application</Label>
              <Input
                type="text"
                id="src_app"
                value={service.application}
                disabled
              />
              <Label for="src_strm">Source Stream</Label>
              <Input
                type="text"
                id="src_strm"
                value={service.stream}
                disabled
              />
              <Label for="dest_addr">Destination Address</Label>
              <Input
                type="text"
                id="dest_addr"
                name="dest_addr"
                value={republishData.dest_addr}
                onChange={handleInputChange}
              />
              <Label for="dest_port">Destination Port</Label>
              <Input
                type="text"
                id="dest_port"
                name="dest_port"
                value={republishData.dest_port}
                onChange={handleInputChange}
              />
              <Label for="dest_app">Destination Application</Label>
              <Input
                type="text"
                id="dest_app"
                name="dest_app"
                value={republishData.dest_app}
                onChange={handleInputChange}
              />
              <Label for="streamKey">Stream Key</Label>
              <Input
                type="text"
                id="streamKey"
                value={streamKey}
                onChange={(e) => setStreamKey(e.target.value)}
                placeholder="Enter Stream Key"
              />
              <Label for="description">Description</Label>
              <Input
                type="text"
                id="description"
                name="description"
                value={republishData.description}
                onChange={handleInputChange}
              />
              <Label for="tags">Tags</Label>
              <Input
                type="text"
                id="tags"
                name="tags"
                value={republishData.tags}
                onChange={handleInputChange}
              />
              <Label for="ssl">SSL</Label>
              <Input
                type="checkbox"
                id="ssl"
                name="ssl"
                checked={republishData.ssl}
                onChange={(e) =>
                  setRepublishData((prevState) => ({
                    ...prevState,
                    ssl: e.target.checked,
                  }))
                }
              />
            </FormGroup>
            <FormGroup>
              <tr>
                <td>
                  {republishData.ssl
                    ? `rtmps://${republishData.dest_addr}:${republishData.dest_port}/${republishData.dest_app}/${republishData.dest_strm}${streamKey}`
                    : `rtmp://${republishData.dest_addr}:${republishData.dest_port}/${republishData.dest_app}/${republishData.dest_strm}${streamKey}`}
                </td>
              </tr>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAddRepublish}>
            Add Republish
          </Button>{" "}
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default Overview;
