const SidebarData = [
  {
    label: "Menu",
    isMainMenu: true,
  },
  {
    label: "Dashboard",
    icon: "mdi mdi-home-variant-outline",
    url: "/reseller-dashboard",
    issubMenubadge: true,
  },
  {
    label: "Server",
    icon: "mdi mdi-server",
    url: "/reseller-server",
    issubMenubadge: true,
  },
  {
    label: "Services",
    icon: "mdi mdi-account-group-outline",
    url: `/reseller-services`,
    issubMenubadge: true,
  },
  {
    label: "Profile",
    icon: "mdi mdi-account-circle",
    url: "/reseller-profile",
    issubMenubadge: true,
  },
];

export default SidebarData;
